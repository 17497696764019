import './SelectorMenu.css';
import React, { useEffect, useState, useRef } from 'react'; // Importar useState
import VillainSelector from './VillainSelector';
import ExpansionSelector from './ExpansionSelector';
import PlayerSelector from './PlayerSelector';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from './StateContext'; // Importa el hook del contexto
import RevisarProximosEncuentros from './RevisarProximosEncuentros';
import UsoLocal from './UsoLocal';
import Button from '@mui/material/Button';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ColorizeIcon from '@mui/icons-material/Colorize';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AvatarGroup from '@mui/material/AvatarGroup';
import { formatDistance } from 'date-fns';
import { es } from 'date-fns/locale'; // Import Spanish locale
import Divider from '@mui/material/Divider';
import axios from 'axios';

const SelectorMenu = () => {
    const clickSoundRef = useRef(null);
    const navigate = useNavigate();
    const { deckInfoContext, setDeckInfoContext } = useStateContext(); // Usa el contexto para actualizar el estado

    const [avatarIndex, setAvatarIndex] = useState(0);
    const [gameStates, setGameStates] = useState([]);

    useEffect(() => {
        fetchGameStates();
      }, []);

      const fetchGameStates = async () => {
        try {
            const response = await axios.get('https://tmbadventures.es/api/game_state');
            setGameStates(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const avatarList = [
        { src: '/static/resources/gearlocs/boomer.png', name: 'Boomer', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/tantrum.png', name: 'Tantrum', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/patches.png', name: 'Patches', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/picket.png', name: 'Picket', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/duster.png', name: 'Duster', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/dart.png', name: 'Dart', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/gasket.png', name: 'Gasket', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/ghillie.png', name: 'Ghillie', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/labrats.png', name: 'Lab rats', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/nugget.png', name: 'Nugget', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/stanza.png', name: 'Stanza', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/tink.png', name: 'Tink', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/static.png', name: 'Static', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/carcass.png', name: 'Carcass', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/figment.png', name: 'Figment', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/gale.png', name: 'Gale', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/riffle.png', name: 'Riffle', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/polaris.png', name: 'Polaris', entrenamiento: 0 }
    ];

    const [avatarIndices, setAvatarIndices] = useState([0, 0, 0, 0]);


    const handlePreviousAvatar = (index) => {
        setAvatarIndices((prevIndices) => {
            const newIndices = [...prevIndices];
            newIndices[index] = newIndices[index] > 0 ? newIndices[index] - 1 : avatarList.length - 1;
            return newIndices;
        });
    };

    const handleNextAvatar = (index) => {
        setAvatarIndices((prevIndices) => {
            const newIndices = [...prevIndices];
            newIndices[index] = newIndices[index] < avatarList.length - 1 ? newIndices[index] + 1 : 0;
            return newIndices;
        });
    };

    const handleIncreasePlayers = () => {
        if (numPlayers < 4) {
            clickSoundRef.current.play();
            setNumPlayers(numPlayers + 1);
        }
    };

    const handleDecreasePlayers = () => {
        if (numPlayers > 1) {
            setNumPlayers(numPlayers - 1);
        }
    };
    
    const villainCategories = [
        //Añadir días a cada villano para limitar el tamaño del array de cartas y parametrizar los encuentros de villano
        {
            category: 'Juego base',
            villains: [
                { name: 'Drellen', clave:'base', keyname: 'drellen', encuentrosTirano: 1, puntosNecesarios: 6, limiteDias: 10, cartaExtra: 0  },
                { name: 'Mulmesh', clave:'base', keyname: 'mulmesh',  encuentrosTirano: 1, puntosNecesarios: 6, limiteDias: 9,  cartaExtra: 0  },
                { name: 'Nom', clave:'base', keyname: 'nom',  encuentrosTirano: 1, puntosNecesarios: 6, limiteDias: 8, cartaExtra: 0  },
                { name: 'Gendricks', clave:'base', keyname: 'gendricks',  encuentrosTirano: 2, puntosNecesarios: 8, limiteDias: 10, cartaExtra: 0  },
                { name: 'Rey Goblin', clave:'base', keyname: 'reygoblin',  encuentrosTirano: 2, puntosNecesarios: 8, limiteDias: 12, cartaExtra: 0  },
                { name: 'Marrow', clave:'base', keyname: 'marrow',  encuentrosTirano: 2, puntosNecesarios: 10, limiteDias: 12, cartaExtra: 0  },
                { name: 'Duster', clave:'base', keyname: 'duster',  encuentrosTirano: 3, puntosNecesarios: 10, limiteDias: 13, cartaExtra: 0  },
            ]
        },
        {
            category: 'Undertow',
            villains: [
                { name: 'Barnacle', clave:'und', keyname: 'barnacle',  encuentrosTirano: 2, puntosNecesarios: 5, limiteDias: 8, cartaExtra: 0  },
                { name: 'Kollossum', clave:'und', keyname: 'kollossum',  encuentrosTirano: 1, puntosNecesarios: 5, limiteDias: 7, cartaExtra: 0  },
                { name: 'Reina Goblin', clave:'und', keyname: 'reinagoblin',  encuentrosTirano: 1, puntosNecesarios: 6, limiteDias: 9, cartaExtra: 0  },
                { name: 'Nobulous', clave:'und', keyname: 'nobulous',  encuentrosTirano: 2, puntosNecesarios: 7, limiteDias: 10, cartaExtra: 1  },
                { name: 'Vol\'kesh', clave:'und', keyname: 'volkesh',  encuentrosTirano: 2, puntosNecesarios: 7, limiteDias: 11, cartaExtra: 0  },
            ]
        },
        {
        category: 'Unbreakeable',
        villains: [
            { name: 'Rok & Rol', clave:'ubk', keyname: 'rokrol',  encuentrosTirano: 2, puntosNecesarios: 5, limiteDias: 7, cartaExtra: 1  },
            { name: 'Cinder', clave:'ubk', keyname: 'cinder',  encuentrosTirano: 2, puntosNecesarios: 6, limiteDias: 9, cartaExtra: 0  },
            { name: 'Domina', clave:'ubk', keyname: 'domina',  encuentrosTirano: 3, puntosNecesarios: 7, limiteDias: 10, cartaExtra: 1  },
            { name: 'Gavenkog', clave:'ubk', keyname: 'gavenkog',  encuentrosTirano: 2, puntosNecesarios: 8, limiteDias: 11, cartaExtra: 0  },
            { name: 'Nexus', clave:'ubk', keyname: 'nexus',  encuentrosTirano: 4, puntosNecesarios: 9, limiteDias: 12, cartaExtra: 1  },
        ]
        },
        {
            category: 'Splice & Dice',
            villains: [
                { name: 'Leech', clave:'syd', keyname: 'leech',  encuentrosTirano: 3, puntosNecesarios: 5, limiteDias: 7, cartaExtra: 0  },
                { name: 'Amanight', clave:'syd', keyname: 'amanight',  encuentrosTirano: 2, puntosNecesarios: 6, limiteDias: 8, cartaExtra: 1  },
                { name: 'Oxide', clave:'syd', keyname: 'oxide',  encuentrosTirano: 4, puntosNecesarios: 7, limiteDias: 9, cartaExtra: 0  },
                { name: 'Bat (Fácil)', clave:'syd', keyname: 'bat',  encuentrosTirano: 4, puntosNecesarios: 6, limiteDias: 8, cartaExtra: 0  },
                { name: 'Bat (Medio)', clave:'syd', keyname: 'bat',  encuentrosTirano: 5, puntosNecesarios: 8, limiteDias: 10, cartaExtra: 0  },
                { name: 'Bat (Difícil)', clave:'syd', keyname: 'bat',  encuentrosTirano: 7, puntosNecesarios: 10, limiteDias: 12, cartaExtra: 0  },
                { name: 'Blobulous', clave:'syd', keyname: 'blobulous',  encuentrosTirano: 4, puntosNecesarios: 6, limiteDias: 8, cartaExtra: 0  },
                { name: 'Locgear', clave:'syd', keyname: 'locgear',  encuentrosTirano: 5, puntosNecesarios: 8, limiteDias: 10, cartaExtra: 1  },
            ]
        },
        {
            category: 'Rage of Tyranny',
            villains: [
                { name: 'Drellen Resentido', clave:'rot', keyname: 'drellenresentido', keynamebasico: 'drellen', encuentrosTirano: 1, puntosNecesarios: 6, limiteDias: 10, keyconjunto: 'base', cartaExtra: 1 },
                { name: 'Gendricks que echa humo', clave:'rot', keyname: 'gendrickshumo', keynamebasico: 'gendricks', encuentrosTirano: 2, puntosNecesarios: 8, limiteDias: 10, keyconjunto: 'base', cartaExtra: 1  },
                { name: 'Kollossum Furioso', clave:'rot', keyname: 'kollossumfurioso', keynamebasico: 'kollossum', encuentrosTirano: 1, puntosNecesarios: 5, limiteDias: 7, keyconjunto: 'und', cartaExtra: 1  },
                { name: 'Reina Goblin Encolerizada', clave:'rot', keyname: 'reinagoblinencol', keynamebasico: 'reinagoblin', encuentrosTirano: 1, puntosNecesarios: 6, limiteDias: 9, keyconjunto: 'und', cartaExtra: 1  },
                { name: 'Duster Indignada', clave:'rot', keyname: 'dusterindignada', keynamebasico: 'duster', encuentrosTirano: 3, puntosNecesarios: 10, limiteDias: 13, keyconjunto: 'base', cartaExtra: 1  },
                { name: 'Mulmesh Enojado', clave:'rot', keyname: 'mulmeshenojado', keynamebasico: 'mulmesh', encuentrosTirano: 1, puntosNecesarios: 6, limiteDias: 9, keyconjunto: 'base', cartaExtra: 1  },
                { name: 'Nobulous Cascarrabias', clave:'rot', keyname: 'nobulouscascarrabias', keynamebasico: 'nobulous', encuentrosTirano: 2, puntosNecesarios: 7, limiteDias: 10, keyconjunto: 'und', cartaExtra: 2  },
                { name: 'Nom Hambrón', clave:'rot', keyname: 'nomhambron', keynamebasico: 'nom', encuentrosTirano: 1, puntosNecesarios: 6, limiteDias: 8, keyconjunto: 'base' },
                { name: 'Rey Goblin Encolerizado', clave:'rot', keyname: 'reygoblinencol', keynamebasico: 'reygoblin', encuentrosTirano: 2, puntosNecesarios: 8, limiteDias: 12, keyconjunto: 'base', cartaExtra: 1  },
                { name: 'Marrow Resentido', clave:'rot', keyname: 'marrowresentido', keynamebasico: 'marrow', encuentrosTirano: 2, puntosNecesarios: 10, limiteDias: 12, keyconjunto: 'base', cartaExtra: 1  },
                { name: 'Vol\'Kesh Irritada', clave:'rot', keyname: 'volkeshirritada', keynamebasico: 'volkesh', encuentrosTirano: 2, puntosNecesarios: 7, limiteDias: 11, keyconjunto: 'und', cartaExtra: 1  },
                { name: 'Barnacle Iracundo', clave:'rot', keyname: 'barnacleiracundo', keynamebasico: 'barnacle', encuentrosTirano: 2, puntosNecesarios: 5, limiteDias: 8, keyconjunto: 'und', cartaExtra: 1  },
            ]
        },
        {
            category: 'Automation of Shale',
            villains: [
                { name: 'Autómata', clave:'aos', keyname: 'automata',  encuentrosTirano: 1, puntosNecesarios: 9, limiteDias: 11, cartaExtra: 1  },
            ]
        }
    ];

    const expansions = [
        { name: 'Base', image: '/static/resources%2Fcover_tmb.jpg?alt=media' },
        { name: 'Undertow', image: '/static/resources%2Fcover_undertow.jpg?alt=media' },
        { name: 'Unbreakeable', image: '/static/resources%2Fcover_unbreakable.jpg?alt=media' },
        { name: 'Age of Tyranny', image: '/static/resources%2Fcover_AoT.png?alt=media' },
        //{ name: 'Splice & Dice', image: 'https://tmbapp.blob.core.windows.net/resources/cover_splice.jpg?alt=media' },
        { name: '40 Dias', image: '/static/resources%2Fcover_40daysDaelore.png?alt=media' },
        { name: '40 Olas', image: '/static/resources%2Fcover_40waves.jpg?alt=media' },
        { name: '40 Cuevas', image: '/static/resources%2Fcover_40caves.jpg?alt=media' },
        { name: 'Rage of Tyranny', image: '/static/resources%2Fcover_RoT.jpg?alt=media' },
        { name: 'Automation of shale', image: '/static/resources%2Fcover_AutomationShale.jpg?alt=media' },
    ];

    const [expansionsGrid, setExpansionsGrid] = useState([expansions[0],expansions[1],expansions[2]]);

    const [selectedVillain, setSelectedVillain] = useState(villainCategories[0].villains[0].name);
    const [selectedExpansions, setSelectedExpansions] = useState(expansionsGrid[0].name);
    const [numPlayers, setNumPlayers] = useState(1);
    const [gearlocs, setGearlocs] = useState(1);
    const [cards] = useState([]);
    let restoEncuentros = [];
    const [isExpansionEmpty, setIsExpansionEmpty] = useState(false);
    const [cartaDelMazo, setCartaDelMazo] = useState(0);

    const handleVillainSelect = (villainName) => {
        setSelectedVillain(villainName);
    };

    const handleExpansionToggle = (expansion, isChecked) => {
        console.log(selectedExpansions);
        setSelectedExpansions((prev) =>
            isChecked ? [...prev, expansion] : prev.filter((exp) => exp !== expansion)
        );
    };



    //FUNCIÓN PARA "BARAJAR" UN MAZO
    const shuffleArray = (array) => {
        let shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    

    const generateDeck =  async () => {
        const allVillains = villainCategories.flatMap(category => category.villains);
        const villain = allVillains.find(v => v.name === selectedVillain);
        const gearlocs = avatarIndices.slice(0, numPlayers).map(index => avatarList[index]);
        console.log(gearlocs);


        const registrarPartida = {
            gearlocs: gearlocs.map(g => g.name).join(', '), // Convert gearlocs to a comma-separated string
            tirano: villain.name,
            state: 'playing'
        };

        var idPartida = 0;

        try {
            // Post the deckInfo to the server
            const response = await axios.post('https://tmbadventures.es/api/game_state', registrarPartida);
            console.log('Data inserted:', response.data);
            idPartida = response.data.id;
        } catch (error) {
            console.error('Error inserting data:', error);
        }

        const deckInfo = {
            id: idPartida,
            numPlayers: numPlayers,
            gearlocs: gearlocs,
            selectedVillain: {
                name: villain.name,
                keyname: villain.keyname,
                encuentrosTirano: villain.encuentrosTirano,
                puntosNecesarios: villain.puntosNecesarios,
                limiteDias: villain.limiteDias,
                clave: villain.clave,
                cartaExtra: villain.cartaExtra,
            },
            selectedExpansions: selectedExpansions,
            diasActual: 1,
            cartaDelMazo: cartaDelMazo,
            RevisarEncuentros: false,
            objetoNoSubirDia: false,
            objetoEncuentro34Dia: false,
            progresoActual: 0
        };

        // Función para generar la lista de 15 cartas
        const generateCardList = () => {

            //LISTADO ENCUENTROS PRIMEROS DÍAS
            const expansionCards = {
                'Base': {
                    dia1: ["encuentro_base_dia1_1"],
                    dia2: ["encuentro_base_dia2_1"],
                    dia3: ["encuentro_base_dia3_1"],
                },
                'Age of Tyranny': {
                    dia1: ["encuentro_aot_dia1_1", "encuentro_aot_dia1_2", "encuentro_aot_dia1_3", "encuentro_aot_dia1_4", "encuentro_aot_dia1_5", "encuentro_aot_dia1_6", "encuentro_aot_dia1_7"],
                    dia2: ["encuentro_aot_dia2_1", "encuentro_aot_dia2_2", "encuentro_aot_dia2_3", "encuentro_aot_dia2_4", "encuentro_aot_dia2_5", "encuentro_aot_dia2_6", "encuentro_aot_dia2_7"],
                    dia3: ["encuentro_aot_dia3_1", "encuentro_aot_dia3_2", "encuentro_aot_dia3_3", "encuentro_aot_dia3_4", "encuentro_aot_dia3_5", "encuentro_aot_dia3_6", "encuentro_aot_dia3_7"],
                },
                'Undertow': {
                    dia1: ["encuentro_und_dia1_1", "encuentro_und_dia1_2", "encuentro_und_dia1_3", "encuentro_und_dia1_4"],
                    dia2: ["encuentro_und_dia2_1", "encuentro_und_dia2_2", "encuentro_und_dia2_3", "encuentro_und_dia2_4"],
                },
                'Automation of shale': {
                    dia1: ["encuentro_aos_dia1_1"],
                    dia2: ["encuentro_aos_dia2_1"],
                    dia3: ["encuentro_aos_dia3_1"],
                },
                'Unbreakeable': {
                    dia1: ["encuentro_ubk_dia1_1", "encuentro_ubk_dia1_2", "encuentro_ubk_dia1_3", "encuentro_ubk_dia1_4", "encuentro_ubk_dia1_5", "encuentro_ubk_dia1_6", "encuentro_ubk_dia1_7", "encuentro_ubk_dia1_8"],
                    dia2: ["encuentro_ubk_dia2_1", "encuentro_ubk_dia2_2", "encuentro_ubk_dia2_3", "encuentro_ubk_dia2_4", "encuentro_ubk_dia2_5", "encuentro_ubk_dia2_6", "encuentro_ubk_dia2_7", "encuentro_ubk_dia2_8"],
                },
                //SI HAY UN TIRANO DE RAGE OF TYRANNY SELECCIONADO, ESTAS CARTAS TIENEN QUE IR SI O SI
                //SI ESTÁ seleccionado el base, añadir dia1_1...4- dia2_1...4-dia3_1...4
                //SI Está seleccionado undertow añadir dia1_5...8, dia2_5...8
                'Rage of Tyranny': {
                    dia1: ["encuentro_rot_dia1_1", "encuentro_rot_dia1_2", "encuentro_rot_dia1_3", "encuentro_rot_dia1_4", "encuentro_rot_dia1_5", "encuentro_rot_dia1_6", "encuentro_rot_dia1_7", "encuentro_rot_dia1_8"],
                    dia2: ["encuentro_rot_dia2_1", "encuentro_rot_dia2_2", "encuentro_rot_dia2_3", "encuentro_rot_dia2_4", "encuentro_rot_dia2_5", "encuentro_rot_dia2_6", "encuentro_rot_dia2_7", "encuentro_rot_dia2_8"],
                    dia3: ["encuentro_rot_dia3_1", "encuentro_rot_dia3_2", "encuentro_rot_dia3_3", "encuentro_rot_dia3_4"],
                }
            };

            // Selección de la carta para el día 1
            let dia1Options = [];
            if(selectedExpansions.length !== 0)selectedExpansions.forEach(exp => {
                if (exp in expansionCards && expansionCards[exp].dia1) {
                    dia1Options = dia1Options.concat(expansionCards[exp].dia1);
                }
            });

            //Día 1: Condición si es un villano de "Rage of Tyranny" Escoger entre las cartas iniciales del base o de Undertow
            let dia1Card = null;
            if(villain.clave==='rot'){
                if(villain.keyconjunto==='base'){
                    dia1Card = expansionCards['Rage of Tyranny'].dia1[Math.floor(Math.random() * 4)];
                }else dia1Card = expansionCards['Rage of Tyranny'].dia1[(Math.floor(Math.random() * 4)+4)];
                cards.push(dia1Card);
            }else{
                if(villain.clave==='aos'){
                    dia1Card = expansionCards['Automation of shale'].dia1[0];
                    cards.push(dia1Card)
                }else
                    //Si no lo es, que seleccione entre todas las cartas de las expansiones seleccionadas
                    if(dia1Options.length !== 0){
                        const dia1Card = dia1Options[Math.floor(Math.random() * dia1Options.length)];
                        cards.push(dia1Card);
                    }
            }

            // Selección de la carta para el día 2
            let dia2Options = [];
            if(selectedExpansions.length !== 0)selectedExpansions.forEach(exp => {
                if (exp in expansionCards && expansionCards[exp].dia2) {
                    dia2Options = dia2Options.concat(expansionCards[exp].dia2);
                }
            });

            //Día 2: Condición si es un villano de "Rage of Tyranny"
            let dia2Card = null;
            if(villain.clave==='rot'){
                if(villain.keyconjunto==='base'){
                   dia2Card = expansionCards['Rage of Tyranny'].dia2[Math.floor(Math.random() * 4)];
                }else {
                    dia2Card = expansionCards['Rage of Tyranny'].dia2[(Math.floor(Math.random() * 4)+4)];
                }
                cards.push(dia2Card);
            }else{ 
                if(villain.clave==='aos'){
                    dia2Card = expansionCards['Automation of shale'].dia2[0];
                    cards.push(dia2Card)
                }else
                //Si no lo es, que seleccione entre todas las cartas de las expansiones seleccionadas
                if(dia2Options.length !== 0){
                    const dia2Card = dia2Options[Math.floor(Math.random() * dia2Options.length)];
                    cards.push(dia2Card);
                }
            }

            // Selección de la carta para el día 3
            let dia3Options = [];
            if(selectedExpansions.length !== 0)selectedExpansions.forEach(exp => {
                if (exp in expansionCards && expansionCards[exp].dia3) {
                    dia3Options = dia3Options.concat(expansionCards[exp].dia3);
                }
            });
            
            //Día 3: Condición si es un villano de "Rage of Tyranny"
            let dia3Card = null;
            if(villain.clave==='rot'){
                // Si es una version RAGE de Undertow, no hay carta de día 3.
                if(villain.keyconjunto !== 'und'){
                    dia3Card = expansionCards['Rage of Tyranny'].dia3[Math.floor(Math.random() * 4)];
                    cards.push(dia3Card);
                }
                
            }else{ 
                if(villain.clave==='aos'){
                    dia3Card = expansionCards['Automation of shale'].dia3[0];
                    cards.push(dia3Card)
                }else
                //Si no lo es, que seleccione entre todas las cartas de las expansiones seleccionadas
                if(dia3Options.length !== 0){
                    const dia3Card = dia3Options[Math.floor(Math.random() * dia3Options.length)];
                    cards.push(dia3Card);
                }
            }


            // Añadir más cartas al mazo aquí según la lógica del juego, hasta completar 15 cartas
            // Se han añadido cartas para los 3 primeros días (aleatorias entre las expansiones seleccionadas). Para no romper el orden, vamos a añadir las 12 cartas restantes
            // en un array auxiliar (restoEncuentros), añadiendo los encuentros del tirano, así como el resto de encuentros generales/solitarios hasta completar los 12.
            // Luego se "barajan" y se concatenan a las cartas seleccionadas para los 3 primeros días y esa es la cadena que se devuelve.
            // NOTA: Hay que tener en cuenta que si está seleccionado un tirano de Rage of tyranny, los encuentros de primeros días deben ser de esta expansión.

            //Añadir los encuentros de tirano posterior a los días iniciales (y luego barajamos)
            let encountersAdded = 0;
            let numerosBatSeleccionados = [];
            while (encountersAdded < villain.encuentrosTirano) {
                encountersAdded++;
                //Condición específica para villanos de Rage of Tyranny. Los encuentros de villano están en su correspondiente villano del base/Undertow. Hay que buscarlo ahí
                let encuentroTirano = null;
                if(villain.clave === 'rot'){
                    encuentroTirano = 'encuentro_tirano_'+villain.keynamebasico+'_'+villain.keyconjunto+'_'+encountersAdded;
                }else{  
                    //Condición específica para modalidad villano a medida de Splice & Dice
                    if(villain.keyname === 'bat'){
                        let seleccionBatAux = Math.floor(Math.random() * 10);
                        while(numerosBatSeleccionados.includes(seleccionBatAux)){
                            seleccionBatAux = Math.floor(Math.random() * 10);
                        }
                        encuentroTirano = 'encuentro_tirano_'+villain.keyname+'_'+villain.clave+'_'+seleccionBatAux;
                        numerosBatSeleccionados.push(seleccionBatAux);
                    }
                    //Resto de villanos
                    else{   
                        encuentroTirano = 'encuentro_tirano_'+villain.keyname+'_'+villain.clave+'_'+encountersAdded;
                    }
                }

                restoEncuentros.push(encuentroTirano);
            }
            //Condiciones para cartas de tirano de las expansiones.
            
            if ((villain.name === 'Drellen' || villain.name === 'Drellen Resentido') && selectedExpansions.includes('40 Dias')) {
                restoEncuentros.push('encuentro_tirano_drellen_40dias_1');
            }else
            if ((villain.name === 'Nom' || villain.name === 'Nom Hambrón') && selectedExpansions.includes('40 Dias')) {
                restoEncuentros.push('encuentro_tirano_nom_40dias_1');
            }else
            if ((villain.name === 'Mulmesh' || villain.name === 'Mulmesh Enojado') && selectedExpansions.includes('40 Dias')) {
                restoEncuentros.push('encuentro_tirano_mulmesh_40dias_1');
            }else
            if ((villain.name === 'Reina Goblin' || villain.name === 'Reina Goblin Encolerizada') && selectedExpansions.includes('40 Olas')) {
                restoEncuentros.push('encuentro_tirano_reinagoblin_40olas_1');
            }else
            if ((villain.name === 'Kollossum' || villain.name === 'Kollossum Furioso') && selectedExpansions.includes('40 Olas')) {
                restoEncuentros.push('encuentro_tirano_kollossum_40olas_1');
            }else
            if (villain.name === 'Cinder' && selectedExpansions.includes('40 Caves')) {
                restoEncuentros.push('encuentro_tirano_cinder_40cave_1');
            }

            const encuentros_general = {
                'Base': {
                    solitario: ["encuentro_base_solo_1", "encuentro_base_solo_2", "encuentro_base_solo_3", "encuentro_base_solo_4", "encuentro_base_solo_5", "encuentro_base_solo_6", "encuentro_base_solo_7", "encuentro_base_solo_8", "encuentro_base_solo_9", "encuentro_base_solo_10", "encuentro_base_solo_11", "encuentro_base_solo_12"],
                    general: ["encuentro_general_base_1", "encuentro_general_base_2", "encuentro_general_base_3", "encuentro_general_base_4", "encuentro_general_base_5", "encuentro_general_base_6", "encuentro_general_base_7", "encuentro_general_base_8", "encuentro_general_base_9", "encuentro_general_base_10", "encuentro_general_base_11", "encuentro_general_base_12", "encuentro_general_base_13", "encuentro_general_base_14", "encuentro_general_base_15", "encuentro_general_base_16", "encuentro_general_base_17", "encuentro_general_base_18", "encuentro_general_base_19", "encuentro_general_base_20", "encuentro_general_base_21", "encuentro_general_base_22", "encuentro_general_base_23", "encuentro_general_base_24", "encuentro_general_base_25", "encuentro_general_base_26", "encuentro_general_base_27", "encuentro_general_base_28", "encuentro_general_base_29", "encuentro_general_base_30"]
                },
                'Undertow': {
                    solitario: ["encuentro_und_solo_1", "encuentro_und_solo_2", "encuentro_und_solo_3", "encuentro_und_solo_4", "encuentro_und_solo_5", "encuentro_und_solo_6", "encuentro_und_solo_7", "encuentro_und_solo_8", "encuentro_und_solo_9", "encuentro_und_solo_10", "encuentro_und_solo_11", "encuentro_und_solo_12"],
                    general: ["encuentro_general_und_1", "encuentro_general_und_2", "encuentro_general_und_3", "encuentro_general_und_4", "encuentro_general_und_5", "encuentro_general_und_6", "encuentro_general_und_7", "encuentro_general_und_8", "encuentro_general_und_9", "encuentro_general_und_10", "encuentro_general_und_11", "encuentro_general_und_12", "encuentro_general_und_13", "encuentro_general_und_14", "encuentro_general_und_15", "encuentro_general_und_16", "encuentro_general_und_17", "encuentro_general_und_18", "encuentro_general_und_19", "encuentro_general_und_20", "encuentro_general_und_21", "encuentro_general_und_22", "encuentro_general_und_23", "encuentro_general_und_24", "encuentro_general_und_25", "encuentro_general_und_26", "encuentro_general_und_27", "encuentro_general_und_28", "encuentro_general_und_29", "encuentro_general_und_30"]
                },
                'Unbreakeable': {
                    solitario: ["encuentro_ubk_solo_1", "encuentro_ubk_solo_2", "encuentro_ubk_solo_3", "encuentro_ubk_solo_4", "encuentro_ubk_solo_5", "encuentro_ubk_solo_6", "encuentro_ubk_solo_7", "encuentro_ubk_solo_8", "encuentro_ubk_solo_9", "encuentro_ubk_solo_10", "encuentro_ubk_solo_11", "encuentro_ubk_solo_12"],
                    general: ["encuentro_general_ubk_1", "encuentro_general_ubk_2", "encuentro_general_ubk_3", "encuentro_general_ubk_4", "encuentro_general_ubk_5", "encuentro_general_ubk_6", "encuentro_general_ubk_7", "encuentro_general_ubk_8", "encuentro_general_ubk_9", "encuentro_general_ubk_10", "encuentro_general_ubk_11", "encuentro_general_ubk_12", "encuentro_general_ubk_13", "encuentro_general_ubk_14", "encuentro_general_ubk_15", "encuentro_general_ubk_16", "encuentro_general_ubk_17", "encuentro_general_ubk_18", "encuentro_general_ubk_19", "encuentro_general_ubk_20", "encuentro_general_ubk_21", "encuentro_general_ubk_22", "encuentro_general_ubk_23", "encuentro_general_ubk_24", "encuentro_general_ubk_25", "encuentro_general_ubk_26", "encuentro_general_ubk_27", "encuentro_general_ubk_28"]
                },
                'Age of Tyranny': {
                    solitario: [],
                    general: []
                },
                'Splice & Dice': {
                    solitario: [],
                    general: []
                },
                '40 Dias': {
                    solitario: ["encuentro_40dias_solo_1", "encuentro_40dias_solo_2", "encuentro_40dias_solo_3", "encuentro_40dias_solo_4", "encuentro_40dias_solo_5", "encuentro_40dias_solo_6", "encuentro_40dias_solo_7", "encuentro_40dias_solo_8", "encuentro_40dias_solo_9", "encuentro_40dias_solo_10", "encuentro_40dias_solo_11", "encuentro_40dias_solo_12"],
                    general: ["encuentro_general_40dias_1", "encuentro_general_40dias_2", "encuentro_general_40dias_3", "encuentro_general_40dias_4", "encuentro_general_40dias_5", "encuentro_general_40dias_6", "encuentro_general_40dias_7", "encuentro_general_40dias_8", "encuentro_general_40dias_9", "encuentro_general_40dias_10", "encuentro_general_40dias_11", "encuentro_general_40dias_12", "encuentro_general_40dias_13", "encuentro_general_40dias_14", "encuentro_general_40dias_15", "encuentro_general_40dias_16", "encuentro_general_40dias_17", "encuentro_general_40dias_18", "encuentro_general_40dias_19", "encuentro_general_40dias_20", "encuentro_general_40dias_21", "encuentro_general_40dias_22", "encuentro_general_40dias_23", "encuentro_general_40dias_24"]
                },
                '40 Olas': {
                    solitario: ["encuentro_40olas_solo_1", "encuentro_40olas_solo_2", "encuentro_40olas_solo_3", "encuentro_40olas_solo_4", "encuentro_40olas_solo_5", "encuentro_40olas_solo_6", "encuentro_40olas_solo_7", "encuentro_40olas_solo_8", "encuentro_40olas_solo_9", "encuentro_40olas_solo_10", "encuentro_40olas_solo_11", "encuentro_40olas_solo_12"],
                    general: ["encuentro_general_40olas_1", "encuentro_general_40olas_2", "encuentro_general_40olas_3", "encuentro_general_40olas_4", "encuentro_general_40olas_5", "encuentro_general_40olas_6", "encuentro_general_40olas_7", "encuentro_general_40olas_8", "encuentro_general_40olas_9", "encuentro_general_40olas_10", "encuentro_general_40olas_11", "encuentro_general_40olas_12", "encuentro_general_40olas_13", "encuentro_general_40olas_14", "encuentro_general_40olas_15", "encuentro_general_40olas_16", "encuentro_general_40olas_17", "encuentro_general_40olas_18", "encuentro_general_40olas_19", "encuentro_general_40olas_20", "encuentro_general_40olas_21", "encuentro_general_40olas_22", "encuentro_general_40olas_23", "encuentro_general_40olas_24", "encuentro_general_40olas_25", "encuentro_general_40olas_26"]
                },
                '40 Cuevas': {
                    solitario: ["encuentro_40cave_solo_1", "encuentro_40cave_solo_2", "encuentro_40cave_solo_3", "encuentro_40cave_solo_4", "encuentro_40cave_solo_5", "encuentro_40cave_solo_6", "encuentro_40cave_solo_7", "encuentro_40cave_solo_8", "encuentro_40cave_solo_9", "encuentro_40cave_solo_10", "encuentro_40cave_solo_11", "encuentro_40cave_solo_12"],
                    general: ["encuentro_general_40cave_1", "encuentro_general_40cave_2", "encuentro_general_40cave_3", "encuentro_general_40cave_4", "encuentro_general_40cave_5", "encuentro_general_40cave_6", "encuentro_general_40cave_7", "encuentro_general_40cave_8", "encuentro_general_40cave_9", "encuentro_general_40cave_10", "encuentro_general_40cave_11", "encuentro_general_40cave_12", "encuentro_general_40cave_13", "encuentro_general_40cave_14", "encuentro_general_40cave_15", "encuentro_general_40cave_16", "encuentro_general_40cave_17", "encuentro_general_40cave_18", "encuentro_general_40cave_19", "encuentro_general_40cave_20", "encuentro_general_40cave_21", "encuentro_general_40cave_22", "encuentro_general_40cave_23", "encuentro_general_40cave_24", "encuentro_general_40cave_25"]
                },
                'Rage of Tyranny': {
                    solitario: [],
                    general: []
                },
                'Automation of shale': {
                    solitario: ["encuentro_aos_solo_1", "encuentro_aos_solo_2", "encuentro_aos_solo_3", "encuentro_aos_solo_4", "encuentro_aos_solo_5"],
                    general: []
                }
            }

            //Función para rellenar las opciones entre las expansiones seleccionadas y si son encuentros para solitario o general
            let encuentrosOptions = [];
            if(selectedExpansions.length !== 0){
                selectedExpansions.forEach(exp => {
                if(numPlayers === 1 ){
                    if (exp in encuentros_general && encuentros_general[exp].solitario && exp !== 'Automation of shale') {
                        encuentrosOptions = encuentrosOptions.concat(encuentros_general[exp].solitario);
                    }
                }else{
                    if (exp in encuentros_general && encuentros_general[exp].general && exp !== 'Automation of shale') {
                        encuentrosOptions = encuentrosOptions.concat(encuentros_general[exp].general);
                    }
                }
                });
            }

            //Rellenamos el resto del mazo (12 cartas restantes) entre las opciones disponibles sin repetir
            let encuentroCardAux = null;
            let posicionEncuentro = Math.floor(Math.random() * encuentrosOptions.length);
            let encuentrosSeleccionados = [];

            if(selectedExpansions.length !== 0){
                if(villain.clave === 'aos'){
                    restoEncuentros.push('encuentro_aos_solo_1', 'encuentro_aos_solo_2', 'encuentro_aos_solo_3', 'encuentro_aos_solo_4', 'encuentro_aos_solo_5');
                    restoEncuentros = shuffleArray(restoEncuentros);
                }
                while (restoEncuentros.length < (villain.limiteDias + villain.encuentrosTirano - cards.length)) {
                    // Filtro para evitar repeticiones
                    if(encuentrosOptions.length !== 0) while(encuentrosSeleccionados.includes(posicionEncuentro)){
                        posicionEncuentro = Math.floor(Math.random() * encuentrosOptions.length);
                    }
                    encuentroCardAux = encuentrosOptions[posicionEncuentro];
                    encuentrosSeleccionados.push(posicionEncuentro);
                    restoEncuentros.push(encuentroCardAux);
                }
            }

            //Barajamos el resto de cartas
            if(villain.clave !== 'aos') restoEncuentros = shuffleArray(restoEncuentros);
            //CONCATENAR cartas primeros días y el resto
            const combinedCards=cards.concat(restoEncuentros);
            return combinedCards;
        };

        // Generar la lista de cartas
        const cardListAux = generateCardList();

        //Actualizar la información JSON con la nueva lista de cartas
        deckInfo.cardList = cardListAux;

        const deckInfoJSON = JSON.stringify(deckInfo, null, 2);
        //console.log('selectorMenu: '+deckInfoJSON);


        if(selectedExpansions.length !== 0){
        // Navega a la nueva vista y pasa el JSON como estado
        setDeckInfoContext(deckInfoJSON);
        //console.log('selector: '+typeof deckInfo);
        navigate('/Prologo', { state: { deckInfoJSON } });
        }
        else {
            setIsExpansionEmpty(true);
            setTimeout(() => {
                setIsExpansionEmpty(false);
            }, 6000); // Mostrar aviso por 6 segundos
        }

    };

    const generateExpansionGrid = () => {
        const allVillains = villainCategories.flatMap(category => category.villains);
        const villain = allVillains.find(v => v.name === selectedVillain);

        if(villain.clave === 'base'){
            setExpansionsGrid([expansions[0],expansions[3],expansions[4],expansions[7]]);
            setSelectedExpansions([expansions[0].name]);
        }
        if(villain.clave === 'und'){
            setExpansionsGrid([expansions[1],expansions[5],expansions[7]]);
            setSelectedExpansions([expansions[1].name]);
        }
        if(villain.clave === 'ubk'){
            setExpansionsGrid([expansions[2],expansions[6]]);
            setSelectedExpansions([expansions[2].name]);
        }
        if(villain.clave === 'aos'){
            setExpansionsGrid([expansions[8],expansions[0],expansions[1]]);
            setSelectedExpansions([expansions[8].name, expansions[0].name]);
        }
        if(villain.clave === 'rot'){
            setExpansionsGrid([expansions[7],expansions[0],expansions[1],expansions[3],expansions[4],expansions[5]]);
            setSelectedExpansions([expansions[7].name]);
        }
        if(villain.clave === 'syd'){
            setExpansionsGrid([expansions[0],expansions[1],expansions[3],expansions[4],expansions[5],expansions[7]]);
            setSelectedExpansions([expansions[0].name]);
        }
    }

    useEffect(() => {
        generateExpansionGrid();
    }, [selectedVillain]);

    return (
        <div className="">
            <div className="logo-image">
                <img
                src="/static/resources%2Flogo.png?alt=media"
                alt="Logo Too Many Bones"
                />
            </div>
            <div className='titulo'>
            <h1>Too Many Bones Adventures</h1>
            </div>
            <div class="subtitulo">Too Many Bones companion</div>


            <div className="player-selector-box">
                <h3>Selecciona el número de jugadores</h3>
                <PlayerSelector
                    numPlayers={numPlayers}
                    onIncrease={handleIncreasePlayers}
                    onDecrease={handleDecreasePlayers}
                />
                <audio ref={clickSoundRef} src="/static/resources/addplayer.mp3" />
                
                <div className="stack-container">
                    {Array.from({ length: numPlayers }).map((_, index) => (
                        <div key={index} className="arrows-container">
                            <ArrowBackIosIcon className="arrows" onClick={() => handlePreviousAvatar(index)} />
                            <div className="avatar-container">
                                <Avatar
                                    alt={avatarList[avatarIndices[index]].name}
                                    src={avatarList[avatarIndices[index]].src}
                                    sx={{ width: 120, height: 120 }}
                                />
                                <div className="avatar-name">{avatarList[avatarIndices[index]].name}</div>
                            </div>
                            <ArrowForwardIosIcon className="arrows" onClick={() => handleNextAvatar(index)} />
                        </div>
                    ))}
                </div>

            </div>

            <VillainSelector
                villainCategories={villainCategories}
                onSelect={handleVillainSelect}
                selectedVillain={selectedVillain}
            />
            <ExpansionSelector 
                expansions={expansionsGrid} 
                onToggle={handleExpansionToggle} 
                selectedExpansions={selectedExpansions} 
            />



            {isExpansionEmpty && (
                <div className="empty-notification">
                    Debes seleccionar al menos una expansión.
                </div>
            )}

            <div className="seccion-boton">
                <Button className='boton-aventura' onClick={generateDeck} variant="contained" startIcon={<ColorizeIcon style={{ transform: 'rotate(90deg)' }} />}>
                    <span className='boton-aventura-text'>Comienza la aventura</span>
                </Button>
            </div>

            <div>




                
            <div style={{ width: '80%', margin: '0 auto', marginTop: '160px', textAlign: 'center', backgroundColor: '#edf7fc', padding: '16px' }}>
            <h3>Too Many Bones App NEWS</h3>
            <List>
                    {gameStates.map(state => {
                        const numGearlocs = state.gearlocs.split(', ').length;
                        const gearlocNames = state.gearlocs.split(', ');
                        const gearlocAvatars = gearlocNames.map(name => avatarList.find(g => g.name === name));
                        const updatedAt = new Date(state.updated_at);
                        updatedAt.setHours(updatedAt.getHours() + 1); // Add 1 hour to the date
                        const timeAgo = formatDistance(updatedAt, new Date(), { addSuffix: true, locale: es });

                        let message;
                        switch (state.state) {
                            case 'playing':
                                if (state.days === 0) {
                                    message = `${numGearlocs > 1 ? 'El grupo de valientes' : 'El valiente y solitario'} <b>${state.gearlocs}</b> empezó la aventura <b>${timeAgo}</b> contra el tirano <b>${state.tirano}</b>`;
                                } else if (state.days === 100) {
                                    message = `<span style="color:#b37400;">${numGearlocs > 1 ? 'El grupo de valientes' : 'El valiente y solitario'} <b>${state.gearlocs}</b> inició hace <b>${timeAgo}</b> el combate final contra el tirano <b>${state.tirano}</b></span>`;
                                } else {
                                    message = `${numGearlocs > 1 ? 'El grupo de valientes' : 'El valiente y solitario'} <b>${state.gearlocs}</b> avanzó al <b>DÍA ${state.days}</b> en la aventura contra <b>${state.tirano}</b> hace <b>${timeAgo}</b>`;
                                }
                                break;
                            case 'lose':
                                message = `<span style="color:#b30000;">${numGearlocs > 1 ? 'El grupo de valientes' : 'El valiente y solitario'} <b>${state.gearlocs}</b> fue derrotado <b>${timeAgo}</b> a manos del tirano <b>${state.tirano}</b></span>`;
                                break;
                            case 'win':
                                message = `<span style="color:#00b300;">${numGearlocs > 1 ? 'El grupo de valientes' : 'El valiente y solitario'} <b>${state.gearlocs}</b> le ganó la partida <b>${timeAgo}</b> al tirano <b>${state.tirano}</b></span>`;
                                break;
                            default:
                                message = '';
                                break;
                        }


                        return (
                            <ListItem key={state.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <AvatarGroup max={4}>
                                    {gearlocAvatars.map((gearloc, index) => (
                                        <Avatar key={index} alt={gearloc.name} src={gearloc.src} />
                                    ))}
                                </AvatarGroup>
                                <ListItemText
                                    style={{ textAlign: 'center' }}
                                    primary={
                                        <span dangerouslySetInnerHTML={{ __html: message }} />
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </div>
    </div>

            <div className="footer">
                Version 0.2.0
            </div>
        </div>
    );
};

export default SelectorMenu;