import React, { useEffect, useState, useRef } from 'react'; // Importar useState
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { Trove } from './Trove'; // Correctly import the rules array
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItem from '@mui/material/ListItem';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';

const TroveModal = ({ isExpanded, onClose }) => {
    const [open, setOpen] = useState(false); // Initialize the open state to false
    const [selectedTrove, setSelectedTrove] = useState(null); // State to store the selected trove
    const inputRef = useRef(null); // Create a reference to the input field
    
    useEffect(() => {
        setOpen(isExpanded);
    }, [isExpanded]);

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw', // Set the width to 90% of the viewport width
        maxWidth: '90vw', // Ensure the maximum width is 90% of the viewport width
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    };

    const handleTroveChange = (event, newValue) => {
        setSelectedTrove(newValue);
        if (inputRef.current) {
            inputRef.current.blur(); // Remove focus from the input field
        }
    };

    return (
        <div>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style} className="modal-rules">
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                    </Typography>
                    <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>



                <h3>Trove</h3>
                <div className="autocomplete">
                    <Autocomplete
                    disablePortal
                    options={Trove}
                    sx={{ width: 300 }}
                    onChange={handleTroveChange} // Update selected rule
                    renderInput={(params) => <TextField 
                                                {...params} 
                                                label="Trove" 
                                                inputRef={inputRef} // Attach the reference to the input field
                                            />}
                />
                </div>
                <div className="rules-info">
                            {selectedTrove ? (
                        <div
                                dangerouslySetInnerHTML={{
                                    __html: `<h3>${selectedTrove.label}</h3><div style="font-size:16px;text-align: justify;">
                                     <div className="central-image">
                                     <img 
                                        className="loot-image" 
                                        style="max-width: 100%; height: auto;" 
                                        src="/static/trove/${selectedTrove.expansion}/${selectedTrove.img}.jpg" 
                                    /></div>
                                     </div>`
                                }}
                            />

                            ) : (
                                'Busca el Trove que necesitas'
                            )}
                </div>
                        <div className="seccion-boton-cerrar">
                        <Button
                                variant="contained"
                                onClick={handleClose}
                            >
                                Cerrar Trove
                            </Button>
                            </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
};

export default TroveModal;