import './Aventura.css';
import React, { useEffect, useState, useRef } from 'react'; // Importar useState
import { useLocation } from 'react-router-dom';
import { useStateContext } from './StateContext'; // Importa el hook del contexto
import { useNavigate } from 'react-router-dom';
import Audio from './Audio';
import UsoLocal from './UsoLocal';
import Fab from '@mui/material/Fab';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Button from '@mui/material/Button';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import LightModeIcon from '@mui/icons-material/LightMode';
import TimelineIcon from '@mui/icons-material/Timeline';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import FolderIcon from '@mui/icons-material/Folder';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import LibroBatalla from './LibroBatalla';
import PuntosEntrenamiento from './PuntosEntrenamiento';
import Loot from './LootModal';
import Trove from './TroveModal';
import axios from 'axios';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import AvatarGroup from '@mui/material/AvatarGroup';
import { formatDistance } from 'date-fns';
import { es } from 'date-fns/locale'; // Import Spanish locale

const Aventura = () => {
    const [zoomLevel, setZoomLevel] = useState(1);
    const flipIcon = document.getElementById('flip-icon');
    const ZoomIcon = document.getElementById('zoom-icon');

    const [gameStates, setGameStates] = useState([]);

    useEffect(() => {
        fetchGameStates();
      }, []);

      const fetchGameStates = async () => {
        try {
            const response = await axios.get('https://tmbadventures.es/api/game_state');
            setGameStates(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const avatarList = [
        { src: '/static/resources/gearlocs/boomer.png', name: 'Boomer', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/tantrum.png', name: 'Tantrum', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/patches.png', name: 'Patches', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/picket.png', name: 'Picket', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/duster.png', name: 'Duster', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/dart.png', name: 'Dart', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/gasket.png', name: 'Gasket', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/ghillie.png', name: 'Ghillie', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/labrats.png', name: 'Lab rats', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/nugget.png', name: 'Nugget', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/stanza.png', name: 'Stanza', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/tink.png', name: 'Tink', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/static.png', name: 'Static', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/carcass.png', name: 'Carcass', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/figment.png', name: 'Figment', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/gale.png', name: 'Gale', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/riffle.png', name: 'Riffle', entrenamiento: 0 },
        { src: '/static/resources/gearlocs/polaris.png', name: 'Polaris', entrenamiento: 0 }
    ];

    //#################################################
    //#################################################
    // VARIABLES DE LA AVENTURA 
    //#################################################
    //#################################################
    const navigate = useNavigate();
    const location = useLocation(); // Hook para obtener el estado de la navegación
    const { deckInfoJSON } = location.state || {}; // Obtén la cadena JSON del estado
    
    const deckInfo = deckInfoJSON ? JSON.parse(deckInfoJSON) : {}; // Parsear JSON si es una cadena
    const { numDays, limDays, bqValue, progress, limProgress, updateNumDays, updateLimDays, updateBqValue, updateProgress, updateLimProgress, updateHeaderFieldProgressColor, updateHeaderFieldDaysColor, deckInfoContext, setDeckInfoContext } = useStateContext(); // Usa el contexto para actualizar el estado

    const { selectedVillain, numPlayers, id } = deckInfo;
    const [gearlocs, setGearlocs] = useState(deckInfo.gearlocs);

    const [cardList, setCardList] = useState(deckInfo.cardList); // Estado para el número de días
    const [diasActual, setDiasActual] = useState(deckInfo.diasActual); // Estado para el número de días
    const [currentImage, setCurrentImage] = useState(null); // Estado para la imagen actual
    const [cartaDelMazo, setCartaDelMazo] = useState(deckInfo.cartaDelMazo);
    const [isImageAlt, setIsImageAlt] = useState(false); // Estado para alternar la imagen
    const [initialized, setInitialized] = useState(false); // Estado para controlar la inicialización
    const [imageChanged, setImageChanged] = useState(false);

    const [progresoActual, setProgresoActual] = useState(deckInfo.progresoActual);

    const [showQuestionBox, setShowQuestionBox] = useState(false);
    const [show3Button, setShow3Button] = useState(false);
    const [showMiniImage] = useState(true);
    const [miniImage, setMiniImage] = useState(null);
    const [showExpandImage, setShowExpandImage] = useState(false);
    const [questionText, setQuestionText] = useState("");
    const [button1Text, setButton1Text] = useState("");
    const [button2Text, setButton2Text] = useState("");
    const [button3Text, setButton3Text] = useState("");
    const [button1Action, setButton1Action] = useState(() => {});
    const [button2Action, setButton2Action] = useState(() => {});
    const [button3Action, setButton3Action] = useState(() => {});
    const [noAvanzarDia, setNoAvanzarDia] = useState(false);

    const [showIndicadorDia, setShowIndicadorDia] = useState(false);
    const [isExplained, setIsExplained] = useState(false);
    const [cartaEspectador, setCartaEspectador] = useState(cartaDelMazo);

    const [cardListChanged, setCardListChanged] = useState(false);
    const [persistenteRevisarEncuentrosBox, setPersistenteRevisarEncuentrosBox] = useState(deckInfo.revisarEncuentros);
    const [showRevisarEncuentrosBox, setShowRevisarEncuentrosBox] = useState(false);

    const [objetoNoSubirDia, setObjetoNoSubirDia] = useState(deckInfo.objetoNoSubirDia);
    const [objetoNoSubirDiaBox, setObjetoNoSubirDiaBox] = useState(false);

    const [objetoEncuentro34Dia, setObjetoEncuentro34Dia] = useState(deckInfo.objetoEncuentro34Dia);
    const [objetoEncuentro34DiaBox, setObjetoEncuentro34DiaBox] = useState(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw', // Set the width to 90% of the viewport width
        maxWidth: '90vw', // Ensure the maximum width is 90% of the viewport width
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    };



    // Función para obtener la url de la imagen en función del tipo de carta
    const getUrlImage = (imgNumber) => { //A falta de añadir '_frontal.jpg?alt=media' o 'trasera.jpg?alt=media'
        let urlTemp = UsoLocal(''); // Inicializa la URL con un valor por defecto
        if (cardList.length === 0 || imgNumber >= cardList.length) urlTemp = null;

        if(/_dia1_|_dia2_|_dia3_/.test(cardList[imgNumber])){
            urlTemp += 'primeros-dias%2F'+cardList[imgNumber] // Buscar en contenedor primeros días
        }
        if(/encuentro_general/.test(cardList[imgNumber])){
            urlTemp += 'encuentros-general%2F'+cardList[imgNumber] // Buscar en encuentros generales
        }
        if(/_solo_/.test(cardList[imgNumber])){
            urlTemp += 'encuentros-solitario%2F'+cardList[imgNumber] // Ajusta la URL de acuerdo con tu estructura
        }
        if(/encuentro_tirano/.test(cardList[imgNumber])){
            urlTemp += 'encuentros-tirano%2F'+cardList[imgNumber] // Ajusta la URL de acuerdo con tu estructura
        }
        if(/encuentro_especial/.test(cardList[imgNumber])){
            urlTemp += 'encuentros-especial%2F'+cardList[imgNumber] // Ajusta la URL de acuerdo con tu estructura
        }

        return urlTemp;
    };

    //#################################################
    //#################################################
    // PREPARACIÓN DE LA VENTANA PARA EL PRIMER USO 
    //#################################################
    //#################################################
    let urlTempImage = '';
    useEffect(() => {
        if (deckInfo && !initialized) {
            updateProgress(progresoActual); // Actualiza el progreso inicial
            updateLimProgress(selectedVillain.puntosNecesarios); // Actualiza el límite de progreso
            updateNumDays(diasActual); // Actualiza el número de días
            updateLimDays(selectedVillain.limiteDias); // Actualiza el límite de días
            updateBqValue(diasActual, numPlayers); // Actualiza el valor BQ
            // Inicializa la imagen actual
            urlTempImage = getUrlImage(cartaEspectador)+'_frontal.jpg?alt=media';
            setCurrentImage(urlTempImage); // Usa el primer elemento para la imagen inicial
            setShowIndicadorDia(true);

            setInitialized(true);
        }

        if(cardListChanged){

            urlTempImage = getUrlImage(cartaEspectador)+'_frontal.jpg?alt=media';
            setCurrentImage(urlTempImage); // Usa el primer elemento para la imagen inicial
            guardarContexto(progresoActual, diasActual, cardList, cartaDelMazo);
            setCardListChanged(false);
        }
        if(imageChanged){
            urlTempImage = getUrlImage(cartaEspectador)+'_frontal.jpg?alt=media';
            setCurrentImage(urlTempImage); // Usa el primer elemento para la imagen inicial
            setImageChanged(false);
        }
        if(noAvanzarDia){
            let nuevoDia = diasActual - 1;
            setDiasActual(nuevoDia);
            updateNumDays(nuevoDia); // Actualiza el número de días
            updateBqValue(nuevoDia,numPlayers);
            setNoAvanzarDia(false);
        }
        if(showIndicadorDia){

        }

    }, [deckInfo, updateNumDays, updateLimDays, updateBqValue, updateProgress, updateLimProgress, cardList, cartaDelMazo, diasActual, noAvanzarDia,showIndicadorDia]);

    const createUpdatedDeckInfo = (deckInfoRecibida, newProgress, newDays, newCardList, newCartaDelMazo) => {
        return {
          ...deckInfoRecibida,
          progresoActual: newProgress, // Modificar el progreso actual
          diasActual: newDays, // Modificar los días actuales
          cardList: newCardList, // Modificar la lista de cartas
          cartaDelMazo: newCartaDelMazo // Actualizar posición de qué carta mostrar
        };
      };

    const guardarContexto = (newProgress, newDays, newCardList, newCartaDelMazo) => {
        const deckInfoRecibida = {
            id: id,
            numPlayers: numPlayers,
            gearlocs: gearlocs,
            selectedVillain: {
                name: selectedVillain.name,
                keyname: selectedVillain.keyname,
                encuentrosTirano: selectedVillain.encuentrosTirano,
                puntosNecesarios: selectedVillain.puntosNecesarios,
                limiteDias: selectedVillain.limiteDias,
                clave: selectedVillain.clave,
                cartaExtra: selectedVillain.cartaExtra,
            },
            diasActual: 1,
            cartaDelMazo: cartaDelMazo,
            progresoActual: 0,
            cardList: cardList,
            revisarEncuentros: persistenteRevisarEncuentrosBox,
            objetoNoSubirDia: objetoNoSubirDia,
            objetoEncuentro34Dia: objetoEncuentro34Dia
        };

        const updatedDeckInfo = createUpdatedDeckInfo(deckInfoRecibida, newProgress, newDays, newCardList, newCartaDelMazo);
        const updatedDeckInfoJSON = JSON.stringify(updatedDeckInfo, null, 2);
        setDeckInfoContext(updatedDeckInfoJSON);
        return updatedDeckInfoJSON;
        //console.log('aventura: '+updatedDeckInfoJSON);
    }

    const handleNextCardWithScroll = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchGameStates(); // Refresh the displayed data
        handleNextCard();
    };

    // Función para mostrar la pregunta con texto y funciones dinámicas
    const craftQuestionBox = (question, btn1Text, btn2Text, btn3Text, btn1Action, btn2Action, btn3Action) => {
        setQuestionText(question);
        setButton1Text(btn1Text);
        setButton2Text(btn2Text);
        setButton3Text(btn3Text);
        setButton1Action(() => btn1Action);
        setButton2Action(() => btn2Action);
        setButton3Action(() => btn3Action);
    }
    const showQuestion = (question, btn1Text, btn2Text, btn3Text, btn1Action, btn2Action, btn3Action) => {
        craftQuestionBox(question, btn1Text, btn2Text, btn3Text, btn1Action, btn2Action, btn3Action);
        setShowQuestionBox(true);
    };

    //FUNCIÓN PARA "BARAJAR" UN MAZO
    const shuffleArray = (array) => {
        let shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };
    //#################################################
    //#################################################
    // SECCIÓN FINAL DE LA AVENTURA
    //#################################################
    //#################################################
    const handleMiniImage = () => {
        urlTempImage = getUrlImage(cartaDelMazo)+'_trasera.jpg?alt=media';
        setMiniImage(urlTempImage); // Poner la cara trasera en la mini imagen
    };

    const closeMiniImage = () => {
        setShowExpandImage(false);
    };

    // Sync: LIBRO DE BATALLA
    const [isMiniDocExpanded, setIsMiniDocExpanded] = useState(false);

    const expandMiniDoc = () => {
        setIsMiniDocExpanded(!isMiniDocExpanded);
    };

    const closeMiniDoc = () => {
        setIsMiniDocExpanded(false);
    };


    // Sync: LOOT AND TROVE
    const [isMiniLootExpanded, setIsMiniLootExpanded] = useState(false);
    const [isMiniTroveExpanded, setIsMiniTroveExpanded] = useState(false);

    const expandMiniLoot = () => {
        setIsMiniLootExpanded(!isMiniLootExpanded);
    };

    const expandMiniTrove = () => {
        setIsMiniTroveExpanded(!isMiniTroveExpanded);
    };

    const closeMiniLoot = () => {
        setIsMiniLootExpanded(false);
    };

    const closeMiniTrove = () => {
        setIsMiniTroveExpanded(false);
    };

    const checkConditionBox = () => {
        if (progresoActual >= selectedVillain.puntosNecesarios) { // PUNTOS SUFICIENTES PARA SIGUIENTE PANTALLA
            if (diasActual >= selectedVillain.limiteDias - 1) { // ÚLTIMO DÍA
                showQuestion("Tienes suficientes puntos de progreso y hoy es la ÚLTIMA OPORTUNIDAD para enfrentarte al villano.", "Enfrentarme al villano", "", "", combateVillano, null, null);
            } else {
                showQuestion("Tienes suficientes puntos de progreso para enfrentarte al villano. ¿Qué deseas hacer?", "Enfrentarme al villano", "Realizar un encuentro", "", combateVillano, continuarEncuentro, null);

            }

        }else{
            if (diasActual === selectedVillain.limiteDias - 1) { // PERDER POR DÍAS
                showQuestion("No has conseguido suficientes puntos de progreso para enfrentarte al villano. ¿Qué deseas hacer?", "Realizar último encuentro", "Volver", "", continuarEncuentro, volverEncuentro, null);
            }
            if (diasActual >= selectedVillain.limiteDias) { // FINAL DE LA AVENTURA
                showQuestion("Final de la aventura", "Ver desenlace", "Volver", "", irEpilogo, volverFinal, null);
            }
        }
    };

    
    const combateVillano = async () => {
        // Redirigir a combate villano
        // Primero actualizar deckInfo

        const updatedDeckInfoJSON = guardarContexto(progresoActual, diasActual + 1, deckInfo.cardList, cartaDelMazo);
        //console.log('aventura: '+updatedDeckInfoJSON);

        setShowQuestionBox(false); // Ocultar el cuadro de pregunta

        // Call the PUT endpoint to update the record to the current day
        try {
            const response = await axios.put(`https://tmbadventures.es/api/game_state/${id}/days`, { days: 100 });
            console.log('Combate contra tirano! ', response.data);
        } catch (error) {
            console.error('Error updating day:', error);
        }

        navigate('/CombateTirano', { state: { updatedDeckInfoJSON } });
    };

    const continuarEncuentro = () => {
        // Continuar con el encuentro
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
        //setShowMiniImage(false);
    };
    const continuarEncuentroSinPagar = () => {
        // Continuar con el encuentro
        setShowRevisarEncuentrosBox(false); // Ocultar el cuadro de pregunta
        //setShowMiniImage(false);
    };
    const continuarEncuentroSinGastarObjeto = () => {
        // Continuar con el encuentro
        setObjetoNoSubirDiaBox(false); // Ocultar el cuadro de pregunta
        setObjetoEncuentro34DiaBox(false); // Ocultar el cuadro de pregunta
        //setShowMiniImage(false);
    };

    const volverEncuentro = () => {
        // Continuar con el encuentro
        handlePreviousCard(0, true);
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
    };

    const volverEncuentroFinal = () => {
        // Continuar con el encuentro
        handlePreviousCard(0, true);
        
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
    };

    const irEpilogo = () => {
        
        const deckInfoJSON = guardarContexto(progresoActual, diasActual + 1, deckInfo.cardList, cartaDelMazo);
        navigate('/Epilogo', { state: { deckInfoJSON, resultado:'derrota' } });

        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
    };

    const volverFinal = () => {
        // Cerrar cuadro para ver el resto de elementos
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
    };



    //#################################################
    //#################################################
    // SECCIÓN EFECTOS AZULES CARTAS 
    //#################################################
    //#################################################
    
    const checkBlueEffect = (cardNumber) => {

        handleMiniImage();
        setShow3Button(false);
        if (cardList[cardNumber] === 'encuentro_especial_base_1' ) { 
            showQuestion("Efecto: Roba otro encuentro para hoy y mezcla esta carta en tu mazo de encuentros", "Aceptar", "Cancelar", "", () => robaOtroEncuentro(cardList[cardNumber]), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_especial_base_3' ) { 
            //setShow3Button(true);
            showQuestion("Efecto: Mezcla este encuentro de vuelta en tu mazo de encuentros. Efecto 2: Si el combate no tiene éxito, mezcla este encuentro en tu mazo de encuentros", "Ejecutar efecto", "Cancelar", "", () => mezclaEncuentro(cardList[cardNumber]), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_especial_und_2' ) { 
            showQuestion("Efecto: Si se tiene éxito, busca inmediatamente y procede (aun hoy) con el encuentro especial \"Laboratorios del Consejo: Peligro biológico\" (Reinicia la batalla y los tapetes Gearloc, manteniendo HP's actuales)", "He tenido éxito", "Cancelar", "", () => añadeEncuentroHoy('encuentro_especial_und_3'), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_especial_und_3' ) { 
            showQuestion("Efecto: Si se tiene éxito, busca inmediatamente y procede (aun hoy) con el encuentro especial \"Laboratorios del Consejo: Fabrica de horrores\" (Reinicia la batalla y los tapetes Gearloc, manteniendo HP's actuales)", "He tenido éxito", "Cancelar", "", () =>añadeEncuentroHoy('encuentro_especial_und_4'), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_especial_und_6' ) { // COMPLEJO. NUEVA VISTA PARA PODER MIRAR 2 CARTAS SUPERIORES DEL MAZO DE ENCUENTROS Y REORDENARLAS
            showQuestion("Efecto: Recoger: Persistente. Durante la fase de recuperación, paga al guía (descarta) 1 botín/tesoro para poder mirar las 2 cartas superiores del mazo de encuentros o reordenarlas a tu gusto", "Aceptar", "Cancelar", "", activarPersistenteRevisarProximosEncuentros, continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_general_40cave_12' ) { 
            showQuestion("Efecto: Independientemente del resultado del encuentro, si algún botín fue añadido por Gearlocs, busca el encuentro especial: \"Tu eterna recompensa\" y colócalo en la parte superior del mazo de encuentros", "Aceptar", "Cancelar", "", () => añadeEncuentroSuperior('encuentro_especial_40cave_2'), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_general_40dias_16' ) { 
            showQuestion("Efecto: Independientemente del resultado del encuentro, coloca el encuentro especial: \"Algo se cuece\" en la parte superior del mazo de encuentros", "Aceptar", "Cancelar", "", () => añadeEncuentroSuperior('encuentro_especial_40dias_1'), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_general_40dias_24' ) { 
            showQuestion("Efecto: Si tenéis éxito, coloca el encuentro especial \"Algo se cuece\" en la parte superior del mazo de encuentros", "He tenido éxito", "Cancelar", "", () => añadeEncuentroSuperior('encuentro_especial_40dias_1'), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_general_base_18' ) { 
            showQuestion("Efecto: Coloca de nuevo este encuentro en la parte superior del mazo de encuentro. No hay recompensas para esta selección", "Aceptar", "Cancelar", "", () => añadeEncuentroSuperior('encuentro_general_base_18'), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_general_und_21' ) { 
            showQuestion("Efecto: Si se entra en el laboratorio, buscar el encuentro especial \"Laboratorios del Consejo: Investigación y Desarrollo\" y colócalo en la parte superior del mazo de encuentros", "He entrado en el laboratorio", "Cancelar", "", () => añadeEncuentroSuperior('encuentro_especial_und_2'), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_general_und_22' ) { 
            showQuestion("Efecto: Si se tiene éxito, el grupo puede añadir el encuentro especial \"Laboratorios del Consejo: Investigación y Desarrollo\" a la parte superior del mazo de encuentros", "He tenido éxito", "Cancelar", "", () => añadeEncuentroSuperior('encuentro_especial_und_2'), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_general_und_23' ) { //3 Opciones  | 
            setShow3Button(true);
            showQuestion("Efecto 1: Si se tiene éxito, el grupo puede añadir el encuentro especial \"Laboratorios del Consejo: Investigación y Desarrollo\" a la parte superior del mazo de encuentros.     Efecto 2: Inmediatamente buscar y proceder (aún hoy) con el encuentro especial \"Laboratorios del Consejo: Fabrica de horrores\". Cada Gearloc pierde 2HP", "Efecto 1", "Efecto 2", "Cancelar", () => añadeEncuentroSuperior('encuentro_especial_und_2'), () => añadeEncuentroHoy('encuentro_especial_und_4'), continuarEncuentro);
        }
        if (cardList[cardNumber] === 'encuentro_general_und_24' ) { 
            showQuestion("Efecto: Si se tiene éxito, el grupo puede añadir el encuentro especial \"Laboratorios del Consejo: Investigación y Desarrollo\" a la parte superior del mazo de encuentros", "He tenido éxito", "Cancelar", "", () => añadeEncuentroSuperior('encuentro_especial_und_2'), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_general_und_30' ) { 
            showQuestion("Efecto: Mezcla este encuentro de vuelta en tu mazo de encuentros.", "Aceptar", "Cancelar", "", () => mezclaEncuentro(cardList[cardNumber]), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_base_dia2_1' ) { //encuentro_base_dia2_1
            showQuestion("Efecto: Despues de cada opción, si pierdes el combate, vuelve a poner este encuentro en la parte superior del mazo de encuentros", "He perdido", "He ganado", "", () => añadeEncuentroSuperior(cardList[cardNumber]), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_rot_dia1_3' ) { // Complejo. Tratar como objeto
            showQuestion("Efecto: Recoger \"Despues de un encuentro que no tenga éxito, no avances el contador de días durante la siguiente fase de nuevo día. Un solo uso\"", "Aceptar", "Cancelar", "",recogerObjetoNoSubirDia, continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_ubk_dia1_8' ) { // Complejo. Tratar como objeto
            showQuestion("Efecto: Recoger \"Persistente. En los días 3 y 4, el grupo puede robar una carta de encuentro adicional. Elige una y coloca la otra de vuelta en la parte superior o en la parte inferior del mazo de encuentros\"", "Aceptar", "Cancelar", "", recogerObjetoEncuentroAdicional, continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_40dias_solo_10' ) { 
            showQuestion("Efecto: Si tienes éxito, puedes colocar el encuentro especial \"Algo se cuece\" encima de tu mazo de encuentros", "He tenido éxito", "Cancelar", "", () => añadeEncuentroSuperior('encuentro_especial_40dias_1'), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_base_solo_9' ) { 
            showQuestion("Efecto: Si tienes éxito, retira el encuentro especial \"Vendedores Mohlnor\" de esta aventura", "He tenido éxito", "Cancelar", "", () => retirarEncuentro('encuentro_especial_base_3'), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_und_solo_9' ) { 
            showQuestion("Efecto: Si tienes éxito, puedes buscar el encuentro especial \"Laboratorios del consejo: Investigación y desarrollo\" y colocarlo en la parte superior del mazo de encuentros", "He tenido éxito", "Cancelar", "", () => añadeEncuentroSuperior('encuentro_especial_und_2'), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_tirano_drellen_base_1' ) { 
            showQuestion("Efecto: Mezcla este encuentro de vuelta en tu mazo de encuentros.", "Aceptar", "Cancelar", "", () => mezclaEncuentro(cardList[cardNumber]), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_tirano_locgear_syd_1' ) { 
            showQuestion("Efecto: Inmediatamente buscar y proceder (aún hoy) con el siguiente encuentro del mazo de encuentros", "Aceptar", "Cancelar", "", cambiarEncuentroSinAvanzarDia, continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_tirano_locgear_syd_4' ) { 
            showQuestion("Efecto: Inmediatamente buscar y proceder (aún hoy) con el siguiente encuentro del mazo de encuentros", "Aceptar", "Cancelar", "", cambiarEncuentroSinAvanzarDia, continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_tirano_locgear_syd_5' ) { 
            showQuestion("Efecto: Inmediatamente buscar y proceder (aún hoy) con el siguiente encuentro del mazo de encuentros", "Aceptar", "Cancelar", "", cambiarEncuentroSinAvanzarDia, continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_tirano_mulmesh_base_1' ) { 
            showQuestion("Efecto: Roba otro encuentro para hoy y mezcla esta carta en tu mazo de encuentros", "Aceptar", "Cancelar", "", () => robaOtroEncuentro(cardList[cardNumber]), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_tirano_nexus_ubk_4' ) { 
            showQuestion("Efecto: Despues de obtener las recompensas del encuentro, mezcla esta carta de vuelta en el mazo de encuentros.", "Mezclar", "Cancelar", "", () => mezclaEncuentro(cardList[cardNumber]), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_tirano_nom_base_1' ) { 
            showQuestion("Efecto: Mezcla esta carta de vuelta en el mazo de encuentros.", "Mezclar", "Cancelar", "", () => mezclaEncuentro(cardList[cardNumber]), continuarEncuentro, null);
        }
        if (cardList[cardNumber] === 'encuentro_base_dia3_1' ) { 
            showQuestion("Efecto: Busca el primer encuentro de tirano de tu mazo y colócalo en la parte superior. Baraja el resto de cartas y déjalas debajo.", "Buscar", "Cancelar", "", primerEncuentroTirano, continuarEncuentro, null);
        }
    };

    const robaOtroEncuentro = (cartaActual) => {
        // Roba otro encuentro para hoy y mezcla esta carta en tu mazo de encuentros
        mezclaEncuentro(cartaActual);
        cambiarEncuentroSinAvanzarDia();

    };
    
    const mezclaEncuentro = (cartaActual) => {
        // Efecto: Mezcla este encuentro de vuelta en tu mazo de encuentros.

        // Verificar si el índice cartaDelMazo es válido
        if (cartaDelMazo >= 0 && cartaDelMazo < cardList.length) {
            // Crear una nueva lista con la cartaActual insertada en la siguiente posición. 
            // Toma el resto de cartas no vistas, las baraja, y las une a las jugadas.
            // Actualiza el contexto. (Diferencia entre las 3 primeras cartas para no perder los días iniciales)
            if(cartaDelMazo >=2 ){
                const cartasRestantes = [
                    cartaActual,
                    ...cardList.slice(cartaDelMazo + 1)      // Todas las cartas después de cartaDelMazo
                ]
                
                const cartasRestantesBarajadas = shuffleArray(cartasRestantes);
    
                const nuevoCardList = [
                    ...cardList.slice(0, cartaDelMazo + 1),  // Todas las cartas hasta la posición cartaDelMazo
                    ...cartasRestantesBarajadas                         // Insertar la cartaDeseada
                ];

            // Actualizar el estado de cardList con la nueva lista
            setCardList(nuevoCardList);

            guardarContexto(progresoActual, diasActual, nuevoCardList, cartaDelMazo);
            setCardListChanged(true);
            }
            else{
                const cartasRestantes = [
                    cartaActual,
                    ...cardList.slice(3)      // Todas las cartas después de los encuentros iniciales
                ]
                
                const cartasRestantesBarajadas = shuffleArray(cartasRestantes);
    
                const nuevoCardList = [
                    ...cardList.slice(0, 3),  // Todas las cartas hasta la posición 3 para guardar los días iniciales
                    ...cartasRestantesBarajadas                         // Insertar la cartaDeseada
                ];

            // Actualizar el estado de cardList con la nueva lista
            setCardList(nuevoCardList);

            guardarContexto(progresoActual, diasActual, nuevoCardList, cartaDelMazo);
            setCardListChanged(true);
            }

        } else {
            console.log("Índice cartaDelMazo fuera de rango.");
        }
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta

    };

    const añadeEncuentroHoy = (cartaDeseada) => {
        // Efecto: Si se tiene éxito, busca inmediatamente y procede (aun hoy) con el encuentro especial 
        // \"Laboratorios del Consejo: Fabrica de horrores\" (Reinicia la batalla y los tapetes Gearloc, manteniendo HP's actuales)

        // Verificar si el índice cartaDelMazo es válido
        if (cartaDelMazo >= 0 && cartaDelMazo < cardList.length) {
            // Crear una nueva lista con la cartaDeseada insertada en la siguiente posición
            const nuevoCardList = [
                ...cardList.slice(0, cartaDelMazo + 1),  // Todas las cartas hasta la posición cartaDelMazo
                cartaDeseada,                            // Insertar la cartaDeseada
                ...cardList.slice(cartaDelMazo + 1)      // Todas las cartas después de cartaDelMazo
            ];

            // Actualizar el estado de cardList con la nueva lista
            setCardList(nuevoCardList);

            guardarContexto(progresoActual, diasActual, nuevoCardList, cartaDelMazo);
            setCardListChanged(true);
            
            cambiarEncuentroSinAvanzarDia();

        } else {
            console.log("Índice cartaDelMazo fuera de rango.");
        }

        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
    };

    const añadeEncuentroSuperior = (cartaDeseada) => {
        // Añade un encuentro a la parte superior del mazo de encuentros

        // Verificar si el índice cartaDelMazo es válido (para guardar las cartas vistas)
        if (cartaDelMazo >= 0 && cartaDelMazo < cardList.length) {
            // Crear una nueva lista con la cartaDeseada insertada en la siguiente posición
            const nuevoCardList = [
                ...cardList.slice(0, cartaDelMazo + 1),  // Todas las cartas hasta la posición cartaDelMazo
                cartaDeseada,                            // Insertar la cartaDeseada
                ...cardList.slice(cartaDelMazo + 1)      // Todas las cartas después de cartaDelMazo
            ];

            // Actualizar el estado de cardList con la nueva lista
            setCardList(nuevoCardList);

            guardarContexto(progresoActual, diasActual, nuevoCardList, cartaDelMazo);
            setCardListChanged(true);

        } else {
            console.log("Índice cartaDelMazo fuera de rango.");
        }

        setShowQuestionBox(false); // Ocultar el cuadro de pregunta

    };

    const activarPersistenteRevisarProximosEncuentros = () => {
        setPersistenteRevisarEncuentrosBox(true);
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
        setShowRevisarEncuentrosBox(true);

    }
    const revisarProximosEncuentros = () => {
        // COMPLEJO. Poder crear nueva vista donde revisar los próximos encuentros y reordenarlos en caso de que se desee.
        // Persistente. Durante la fase de recuperación, paga al guía (descarta) 1 botín/tesoro para poder mirar las 2 cartas superiores del mazo de encuentros o reordenarlas a tu gusto
        const deckInfoJSON = guardarContexto(progresoActual, diasActual, cardList, cartaDelMazo);
        setShowRevisarEncuentrosBox(false);
        navigate('/RevisarProximosEncuentros', { state: { deckInfoJSON } });
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta

    };

    const recogerObjetoNoSubirDia = () => {
        // Despues de un encuentro que no tenga éxito, no avances el contador de días durante la siguiente fase de nuevo día. Un solo uso
        setObjetoNoSubirDia(true);
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta

    };
    const usarObjetoNoSubirDia = () => {
        // Despues de un encuentro que no tenga éxito, no avances el contador de días durante la siguiente fase de nuevo día. Un solo uso
        setNoAvanzarDia(true);
        setObjetoNoSubirDia(false);
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
        setObjetoNoSubirDiaBox(false);

    };

    const recogerObjetoEncuentroAdicional = () => {
        // Persistente. En los días 3 y 4, el grupo puede robar una carta de encuentro adicional. Elige una y coloca la otra de vuelta en la parte superior o en la parte inferior del mazo de encuentros\"
        setObjetoEncuentro34Dia(true);
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
    };
    const usarObjetoEncuentroAdicional = () => {
        // Persistente. En los días 3 y 4, el grupo puede robar una carta de encuentro adicional. Elige una y coloca la otra de vuelta en la parte superior o en la parte inferior del mazo de encuentros\"
        const deckInfoJSON = guardarContexto(progresoActual, diasActual, cardList, cartaDelMazo);
        setShowRevisarEncuentrosBox(false);
        navigate('/RevisarProximosEncuentros', { state: { deckInfoJSON } });
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
        setObjetoEncuentro34DiaBox(false);
    };

    const retirarEncuentro = (cartaDeseada) => {
        // retira el encuentro "XXX" de esta aventura
        if(cardList.includes(cartaDeseada)){
            const nuevoCardList = cardList.filter(item => item !== cartaDeseada);
            setCardList(nuevoCardList);

            const updatedDeckInfoJSON = guardarContexto(progresoActual, diasActual, nuevoCardList, cartaDelMazo);
            setCardListChanged(true);
            //console.log('aventura: '+updatedDeckInfoJSON);
        }
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
    };

    const cambiarEncuentroSinAvanzarDia = () => {
        // Inmediatamente buscar y proceder (aún hoy) con el siguiente encuentro del mazo de encuentros

        setNoAvanzarDia(true);
        handleNextCard();
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
    };

    const primerEncuentroTirano = () => {
        // Efecto: Busca el primer encuentro de tirano de tu mazo y colócalo en la parte superior. Baraja el resto de cartas y déjalas debajo.

        // Buscar la posición del primer elemento que empiece por "encuentro_tirano_"
        const tiranoIndex = cardList.findIndex(card => card.startsWith("encuentro_tirano_"));
    
        // Si no se encuentra ningún encuentro tirano, devuelve el cardList original
        if (tiranoIndex === -1) {
            console.log("No se encontró ningún encuentro tirano.");
        }
        else{
        // Crear el nuevo cardList:
        const prevCards = cardList.slice(0, cartaDelMazo + 1); // Cartas previas hasta cartaDelMazo inclusive
        const tiranoCard = cardList[tiranoIndex]; // La carta del tirano encontrada
        const remainingCards = cardList.slice(cartaDelMazo + 1, tiranoIndex).concat(cardList.slice(tiranoIndex + 1)); // Cartas restantes
        const remainingCardsShuffled = shuffleArray(remainingCards);
    
        // Concatenar los tres segmentos para formar el nuevo cardList
        const newCardList = [...prevCards, tiranoCard, ...remainingCardsShuffled];
    
        setCardList(newCardList);

        guardarContexto(progresoActual, diasActual, newCardList, cartaDelMazo);
        setCardListChanged(true);

        }
        setShowQuestionBox(false); // Ocultar el cuadro de pregunta
    };

    //#################################################
    //#################################################
    // BOTONES SECCIÓN INFERIOR
    //#################################################
    //#################################################

    // Función para manejar el clic en la imagen y mostrar la trasera o frontal
    const handleExplanationDay = () => {
        setIsExplained(true);
        setTimeout(() => {
            setIsExplained(false);
        }, 3000); // Mostrar aviso por 6 segundos
    };

    // Función para manejar el clic en la imagen y mostrar la trasera o frontal
    const handleImageClick = () => {
        flipIcon.style.color = isImageAlt ? '#353535' : '#C21807';
        setIsImageAlt(prev => !prev);
        if(!isImageAlt){
            urlTempImage = getUrlImage(cartaEspectador)+'_trasera.jpg?alt=media';
            setCurrentImage(urlTempImage); // Cambia la imagen al hacer clic
        }else{
            urlTempImage = getUrlImage(cartaEspectador)+'_frontal.jpg?alt=media';
            setCurrentImage(urlTempImage); // Cambia la imagen al hacer clic
        } 
    };

    const handleZoomIn = () => {
        setZoomLevel((prevZoomLevel) => {
            const newZoomLevel = prevZoomLevel === 1 ? 1.2 : 1;
            ZoomIcon.style.color = newZoomLevel === 1 ? '#353535' : '#C21807';
            return newZoomLevel;
        });
    };

    // Función para manejar el botón "Carta previa"
    const handlePreviousCard = (numDias, volverFinal) => {
        let nuevoDia = 0;
        let nuevaCartaEspectador = 0;
        if (cartaEspectador > 0) {
            setShowIndicadorDia(false);
            nuevaCartaEspectador = cartaEspectador - numDias;
            nuevoDia = diasActual - numDias;

            setCartaEspectador(nuevaCartaEspectador);

            console.log('volver final: '+volverFinal);
            if(volverFinal){
                setShowIndicadorDia(true);
                setDiasActual(nuevoDia);
                updateNumDays(nuevoDia); // Actualiza el número de días
                updateBqValue(nuevoDia, numPlayers); // Actualiza el valor BQ
            }

            setCurrentImage(getUrlImage(cartaEspectador - numDias) + '_frontal.jpg?alt=media');
            setImageChanged(true);
            setIsImageAlt(false);
        }
        console.log("carta espectador: "+nuevoDia);
    };

    // Función para manejar el botón "Carta siguiente"
    const handleNextCard = async () => {

        if (showIndicadorDia && persistenteRevisarEncuentrosBox) {
            setShowRevisarEncuentrosBox(true);
        }
        if (showIndicadorDia && objetoNoSubirDia) {
            setObjetoNoSubirDiaBox(true);
        }
        if (showIndicadorDia && objetoEncuentro34Dia && (diasActual === 3 || diasActual === 4)) {
            setObjetoEncuentro34DiaBox(true);
        }
        if (showIndicadorDia) {
            checkBlueEffect(cartaDelMazo);
        }
            let nuevoValorCartaEspectador = 0;
        if (cartaEspectador < cartaDelMazo) {
            nuevoValorCartaEspectador = cartaEspectador + 1
            setCartaEspectador(nuevoValorCartaEspectador);
            setImageChanged(true);
            setCardListChanged(true);
            if(nuevoValorCartaEspectador === cartaDelMazo){
                setShowIndicadorDia(true);
            }
        } else {
            if (cartaDelMazo < cardList.length - 1) {
                setCartaDelMazo(cartaDelMazo + 1);
                setCartaEspectador(cartaEspectador + 1);
                setImageChanged(true);
                setCardListChanged(true);
                setShowIndicadorDia(true); 
            }
        }

        // Asegurar que el indicador de día se muestra cuando corresponda
        if (cartaEspectador  === cartaDelMazo && diasActual < selectedVillain.limiteDias + 1) {
            let nuevoDia = diasActual + 1;

            //if(nuevoDia<=selectedVillain.limiteDias){
                updateNumDays(nuevoDia);
                updateBqValue(nuevoDia, numPlayers); // Actualiza el valor BQ
            //} 
            setDiasActual(nuevoDia);

            updateHeaderFieldDaysColor('green-background'); // Cambia el color a verde
            setTimeout(() => {
                updateHeaderFieldDaysColor(''); // Restablece el color después de 0.5 segundos
            }, 500);

            urlTempImage = getUrlImage(cartaEspectador)+'_frontal.jpg?alt=media';
            setCurrentImage(urlTempImage);
            setIsImageAlt(false);

            // Call the PUT endpoint to update the record to the current day
            try {
                const response = await axios.put(`https://tmbadventures.es/api/game_state/${id}/days`, { days: nuevoDia });
                console.log('Day updated:', response.data);
            } catch (error) {
                console.error('Error updating day:', error);
            }

            guardarContexto(progresoActual, nuevoDia, cardList, cartaDelMazo);
        }

        if (showIndicadorDia) {
            checkConditionBox();
        }

    };

    const clickSoundRef = useRef(null);

    // Función para manejar el botón "Subir progreso"
    const handleProgressUp = () => {
        if (clickSoundRef.current) {
            clickSoundRef.current.play();
        }
        updateHeaderFieldProgressColor('green-background'); // Cambia el color a verde
        setTimeout(() => {
            updateHeaderFieldProgressColor(''); // Restablece el color después de 0.5 segundos
        }, 500);

        //Actualizamos variable y cabecera
        let nuevoProgreso = progresoActual + 1;
        setProgresoActual(nuevoProgreso);
        updateProgress(nuevoProgreso);

       const updatedDeckInfoJSON = guardarContexto(nuevoProgreso, diasActual, cardList, cartaDelMazo);

        console.log(updatedDeckInfoJSON);
    };

    // Función para manejar el botón "Bajar progreso"
    const handleProgressDown = () => {
        //Progreso no puede ser negativo
        if(progresoActual > 0){
            //Actualizamos variable y cabecera
            let nuevoProgreso = progresoActual - 1;
            setProgresoActual(nuevoProgreso);
            updateProgress(nuevoProgreso);

            //Efecto visual
            updateHeaderFieldProgressColor('red-background'); // Cambia el color a rojo
            setTimeout(() => {
                updateHeaderFieldProgressColor(''); // Restablece el color después de 0.5 segundos
            }, 500);

            //ACTUALIZAR JSON EN EL CONTEXTO PARA PODER RECUPERAR LA PARTIDA CON LA NUEVA CARTA
            guardarContexto(nuevoProgreso, diasActual, cardList, cartaDelMazo);

        }
    };

    const getAventuraAudio = (currentImage) => {
        if (!currentImage) return null;
            
        const audioSrc = currentImage.split('.jpg')[0].replace('/website/', '/website/audio/') + '.mp3';
        return audioSrc;
    }

    //#################################################
    //#################################################
    // CÓDIGO QUE SE MUESTRA EN PANTALLA 
    //#################################################
    //#################################################
    return (
        <div className="">
            <div className="top-info">
            <Stack direction="row" spacing={1}>
                    <div>
                    <div><Chip icon={<TimelineIcon style={{ color: '#880808' }} />} label="Progreso" className="chip-progreso" /></div>
                        <div className="info-value">{progress} / {limProgress}</div>
                    </div>
                    <div>
                    <div><Chip icon={<LightModeIcon style={{ color: '#FFBF00' }}/>} label="Dias" className="chip-dias" /></div>
                        <div className="info-value">{numDays} / {limDays}</div>
                    </div>
                    <div>
                    <div><Chip icon={<SocialDistanceIcon style={{ color: '#097969' }}/>} label="BQ" className="chip-bq"/></div>
                        <div className="info-value">{bqValue}</div>
                    </div>
                    </Stack>
            </div>
            



            <div className={`image-container ${showIndicadorDia ? '' : 'old'}`}>
                <div className={`central-image ${showIndicadorDia ? 'espacio' : ''}`}>
                    <img
                        src={currentImage}
                        alt="Current view"
                        style={{ transform: `scale(${zoomLevel})` }}
                        onClick={handleImageClick}
                    />
                    
                    <div class="tools">
                        <Fab className="tool" color="default" aria-label="terms" onClick={expandMiniDoc}>
                                <AutoStoriesIcon id="doc-icon"/>
                        </Fab>

                        <Fab className="tool" color="default" aria-label="remove" onClick={handleImageClick}>
                                <FlipCameraAndroidIcon id="flip-icon"/>
                        </Fab>
                        <Fab className="tool" color="default" aria-label="zoom-in" onClick={handleZoomIn}>
                            <ZoomInIcon id="zoom-icon"/>
                        </Fab>
                        <Audio audiosrc={getAventuraAudio(currentImage)}/>

                    </div>

                </div>
            </div>

            <LibroBatalla isExpanded={isMiniDocExpanded} onClose={closeMiniDoc} />

            <div className="progreso-chip-control">
                <h3>Añade puntos de entrenamiento</h3>
            </div>

            <PuntosEntrenamiento gearlocs={gearlocs} setGearlocs={setGearlocs} />

            <div className="progreso-chip-control">
            <h3>Añade puntos de progreso</h3>

            </div>
            <div className="progreso-control">
                    <Fab onClick={handleProgressDown} color="primary" aria-label="remove" disabled={progresoActual === 0 || showQuestionBox}>
                        <RemoveIcon className="player-selector-button"/>
                    </Fab>
                    <Chip label={`${progress} / ${limProgress}`} className="player-selector-chip" readOnly/>
                    <Fab onClick={handleProgressUp} color="primary" aria-label="add" disabled={showQuestionBox}>
                        <AddIcon className="player-selector-button"/>
                    </Fab>
                </div>

            <div className="progreso-chip-control">
                <h3>Loot / Trove Loot</h3>
            </div>
            <div class="tools">
                <Fab className="tool" color="default" aria-label="terms" onClick={expandMiniLoot}>
                        <FolderIcon id="loot-icon"/>
                </Fab>
                <Fab className="tool" color="default" aria-label="terms" onClick={expandMiniTrove}>
                        <FolderSpecialIcon id="trove-icon"/>
                </Fab>
            </div>

            <Loot isExpanded={isMiniLootExpanded} onClose={closeMiniLoot} />
            <Trove isExpanded={isMiniTroveExpanded} onClose={closeMiniTrove} />

            <div className="controls-container">
                <div className="controls">
                <Button onClick={() => handlePreviousCard(1, false)} variant="contained" disabled={diasActual === 1 || showQuestionBox}>
                    Atrás
                </Button>
                <Button onClick={handleNextCardWithScroll} variant="contained"  disabled={showQuestionBox}>
                Siguiente día
                </Button>
                </div>
            </div>


            {/* Cuadro general para condiciones y efectos azules */}
            <Modal
                keepMounted
                open={showQuestionBox}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                </Typography>
                <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>

                {showMiniImage && (
                        <div className="central-image">
                            <img
                            src={miniImage}
                            alt="Current view"
                            />
                        </div>
                        )}
                        <p>{questionText}</p>
                        <div className="button-group">
                        <Button onClick={button1Action}>{button1Text}</Button>
                        {button2Action && <Button onClick={button2Action}>{button2Text}</Button>}
                        {show3Button && <Button onClick={button3Action}>{button3Text}</Button>}
                        </div>
                </Typography>
                </Box>
            </Modal>

            <audio ref={clickSoundRef} src="/static/resources/progressup.mp3" />

            {/* Cuadro para efecto azul: Revisar próximos encuentros. Persistente */}
            {showRevisarEncuentrosBox && (
            <div className="question-box">
                <p>Paga al guía (descarta) 1 botín/tesoro para poder mirar las 2 cartas superiores del mazo de encuentros o reordenarlas a tu gusto</p>
                <div className="button-group">
                    <Button variant="contained" onClick={revisarProximosEncuentros} style={{minHeight: '50px'}}>Pagar</Button>
                    <Button variant="contained" onClick={continuarEncuentroSinPagar} style={{minHeight: '50px'}}>Cancelar</Button>
                </div>
            </div>
            )}

            {/* Cuadro para efecto azul: Avanzar encuentro sin avanzar día. 1 uso */}
            {objetoNoSubirDiaBox && (
            <div className="question-box">
                <p>Despues de un encuentro que no tenga éxito, no avances el contador de días durante la siguiente fase de nuevo día. Un solo uso</p>
                <div className="button-group">
                <button onClick={usarObjetoNoSubirDia}>Gastar carta</button>
                <button onClick={continuarEncuentroSinGastarObjeto}>Cancelar</button>
                </div>
            </div>
            )}

            {/* Cuadro para efecto azul: Cambiar orden encuentros días 3/4 */}
            {objetoEncuentro34DiaBox && (
            <div className="question-box">
                <p>Usar: Persistente. En los días 3 y 4, el grupo puede robar una carta de encuentro adicional. Elige una y coloca la otra de vuelta en la parte superior o en la parte inferior del mazo de encuentros</p>
                <div className="button-group">
                <button onClick={usarObjetoEncuentroAdicional}>Usar carta</button>
                <button onClick={continuarEncuentroSinGastarObjeto}>Cancelar</button>
                </div>
            </div>
            )}

        {isExplained && (
        <div className="save-notification">
          Esta es la carta que está en juego actualmente.
        </div>
      )}

<div style={{ width: '80%', margin: '0 auto', marginTop: '160px', textAlign: 'center', backgroundColor: '#edf7fc', padding: '16px' }}>
            <h3>Too Many Bones App NEWS</h3>
            <List>
                    {gameStates.map(state => {
                        const numGearlocs = state.gearlocs.split(', ').length;
                        const gearlocNames = state.gearlocs.split(', ');
                        const gearlocAvatars = gearlocNames.map(name => avatarList.find(g => g.name === name));
                        const updatedAt = new Date(state.updated_at);
                        updatedAt.setHours(updatedAt.getHours() + 1); // Add 1 hour to the date
                        const timeAgo = formatDistance(updatedAt, new Date(), { addSuffix: true, locale: es });

                        let message;
                        switch (state.state) {
                            case 'playing':
                                if (state.days === 0) {
                                    message = `${numGearlocs > 1 ? 'El grupo de valientes' : 'El valiente y solitario'} <b>${state.gearlocs}</b> empezó la aventura <b>${timeAgo}</b> contra el tirano <b>${state.tirano}</b>`;
                                } else if (state.days === 100) {
                                    message = `<span style="color:#b37400;">${numGearlocs > 1 ? 'El grupo de valientes' : 'El valiente y solitario'} <b>${state.gearlocs}</b> inició hace <b>${timeAgo}</b> el combate final contra el tirano <b>${state.tirano}</b></span>`;
                                } else {
                                    message = `${numGearlocs > 1 ? 'El grupo de valientes' : 'El valiente y solitario'} <b>${state.gearlocs}</b> avanzó al <b>DÍA ${state.days}</b> en la aventura contra <b>${state.tirano}</b> hace <b>${timeAgo}</b>`;
                                }
                                break;
                            case 'lose':
                                message = `<span style="color:#b30000;">${numGearlocs > 1 ? 'El grupo de valientes' : 'El valiente y solitario'} <b>${state.gearlocs}</b> fue derrotado <b>${timeAgo}</b> a manos del tirano <b>${state.tirano}</b></span>`;
                                break;
                            case 'win':
                                message = `<span style="color:#00b300;">${numGearlocs > 1 ? 'El grupo de valientes' : 'El valiente y solitario'} <b>${state.gearlocs}</b> le ganó la partida <b>${timeAgo}</b> al tirano <b>${state.tirano}</b></span>`;
                                break;
                            default:
                                message = '';
                                break;
                        }


                        return (
                            <ListItem key={state.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <AvatarGroup max={4}>
                                    {gearlocAvatars.map((gearloc, index) => (
                                        <Avatar key={index} alt={gearloc.name} src={gearloc.src} />
                                    ))}
                                </AvatarGroup>
                                <ListItemText
                                    style={{ textAlign: 'center' }}
                                    primary={
                                        <span dangerouslySetInnerHTML={{ __html: message }} />
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </div>
    </div>
    );
};

export default Aventura;
