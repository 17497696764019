export const Loot = [
  { label: 'flare distraction', img: 'flare_distraction', expansion: 'base' }, 
  { label: 'fortunate recovery 01 nugget', img: 'fortunate_recovery_01_nugget', expansion: 'base' }, 
  { label: 'fortunate recovery 02 Boomer', img: 'fortunate_recovery_02_Boomer', expansion: 'base' }, 
  { label: 'fortunate recovery 03 Picket', img: 'fortunate_recovery_03_Picket', expansion: 'base' }, 
  { label: 'fortunate recovery 04 Tink', img: 'fortunate_recovery_04_Tink', expansion: 'base' }, 
  { label: 'fortunate recovery 05 Ghillie', img: 'fortunate_recovery_05_Ghillie', expansion: 'base' }, 
  { label: 'fortunate recovery 06 Tantrum', img: 'fortunate_recovery_06_Tantrum', expansion: 'base' }, 
  { label: 'fortunate recovery 07 Patches', img: 'fortunate_recovery_07_Patches', expansion: 'base' }, 
  { label: 'fresh bog meat', img: 'fresh_bog_meat', expansion: 'base' }, 
  { label: 'Gadget arm', img: 'Gadget_arm', expansion: 'base' }, 
  { label: 'infused infunded', img: 'infused_infunded', expansion: 'base' }, 
  { label: 'last battle stew', img: 'last_battle_stew', expansion: 'base' }, 
  { label: 'mech-pick', img: 'mech-pick', expansion: 'base' }, 
  { label: 'Mechanical Assitence', img: 'Mechanical_Assitence', expansion: 'base' }, 
  { label: 'mixed berries 01', img: 'mixed_berries_01', expansion: 'base' }, 
  { label: 'mixed berries 02', img: 'mixed_berries_02', expansion: 'base' }, 
  { label: 'multi-tool', img: 'multi-tool', expansion: 'base' }, 
  { label: 'Oggot herbs 01', img: 'Oggot_herbs_01', expansion: 'base' }, 
  { label: 'Oggot herbs 02', img: 'Oggot_herbs_02', expansion: 'base' }, 
  { label: 'podwer reflex', img: 'podwer_reflex', expansion: 'base' }, 
  { label: 'Prying Iron', img: 'Prying_Iron', expansion: 'base' }, 
  { label: 'raider armor', img: 'raider_armor', expansion: 'base' }, 
  { label: 'Red minotaur', img: 'Red_minotaur', expansion: 'base' }, 
  { label: 'reinforced buckler', img: 'reinforced_buckler', expansion: 'base' }, 
  { label: 'rustic optics', img: 'rustic_optics', expansion: 'base' }, 
  { label: 'skinning knife', img: 'skinning_knife', expansion: 'base' }, 
  { label: 'sparring weapon', img: 'sparring_weapon', expansion: 'base' }, 
  { label: 'Spirit of Ebon', img: 'Spirit_of_Ebon', expansion: 'base' }, 
  { label: 'stone hammer', img: 'stone_hammer', expansion: 'base' }, 
  { label: 'tattered map', img: 'tattered_map', expansion: 'base' }, 
  { label: 'tewaren Gem 01', img: 'tewaren_Gem_01', expansion: 'base' }, 
  { label: 'tewaren Gem 02', img: 'tewaren_Gem_02', expansion: 'base' }, 
  { label: 'throwing axe', img: 'throwing_axe', expansion: 'base' }, 
  { label: 'treasure trove map', img: 'treasure_trove_map', expansion: 'base' }, 
  { label: 'troll brew', img: 'troll_brew', expansion: 'base' }, 
  { label: 'utility parts 01', img: 'utility_parts_01', expansion: 'base' }, 
  { label: 'utility parts 02', img: 'utility_parts_02', expansion: 'base' }, 
  { label: 'Zelfey root', img: 'Zelfey_root', expansion: 'base' }, 
  { label: 'Zelfey seeds 01', img: 'Zelfey_seeds_01', expansion: 'base' }, 
  { label: 'Zelfey seeds 02', img: 'Zelfey_seeds_02', expansion: 'base' },
  { label: 'Badde Ball', img: 'Badde_Ball', expansion: 'automationofshale' }, 
  { label: 'Big Rock', img: 'Big_Rock', expansion: 'automationofshale' }, 
  { label: 'Charred Rations', img: 'Charred_Rations', expansion: 'automationofshale' }, 
  { label: 'Cracked Chalice', img: 'Cracked_Chalice', expansion: 'automationofshale' }, 
  { label: 'Dangerous Dart', img: 'Dangerous_Dart', expansion: 'automationofshale' }, 
  { label: 'Fantabulous Contraption', img: 'Fantabulous_Contraption', expansion: 'automationofshale' }, 
  { label: 'Ruby Red Goblin Shoes', img: 'Ruby_Red_Goblin_Shoes', expansion: 'automationofshale' }, 
  { label: 'Shovel', img: 'Shovel', expansion: 'automationofshale' }, 
  { label: 'Tactical Shrubbery', img: 'Tactical_Shrubbery', expansion: 'automationofshale' }, 
  { label: 'The Wingless Dragon', img: 'The_Wingless_Dragon', expansion: 'automationofshale' }, 
  { label: 'War Paint', img: 'War_Paint', expansion: 'automationofshale' },
  { label: 'Bat Legs', img: 'Bat_Legs', expansion: 'unbreakable' }, 
  { label: 'Battle Yo-yo', img: 'Battle_Yo-yo', expansion: 'unbreakable' }, 
  { label: 'Big Ol Magnet', img: 'Big_Ol_Magnet', expansion: 'unbreakable' }, 
  { label: 'Burnt Trove Map', img: 'Burnt_Trove_Map', expansion: 'unbreakable' }, 
  { label: 'comfy Hinking Boots', img: 'comfy_Hinking_Boots', expansion: 'unbreakable' }, 
  { label: 'Day Planner', img: 'Day_Planner', expansion: 'unbreakable' }, 
  { label: 'Edible Fungi', img: 'Edible_Fungi', expansion: 'unbreakable' }, 
  { label: 'Fortunate Discovery 1', img: 'Fortunate_Discovery_1', expansion: 'unbreakable' }, 
  { label: 'Fortunate Discovery 2', img: 'Fortunate_Discovery_2', expansion: 'unbreakable' }, 
  { label: 'Fortunate discovery 3', img: 'Fortunate_discovery_3', expansion: 'unbreakable' }, 
  { label: 'Gale Force Winds', img: 'Gale_Force_Winds', expansion: 'unbreakable' }, 
  { label: 'Gearloc Grop', img: 'Gearloc_Grop', expansion: 'unbreakable' }, 
  { label: 'Ice Pack', img: 'Ice_Pack', expansion: 'unbreakable' }, 
  { label: 'Performance Enhacing Slugs', img: 'Performance_Enhacing_Slugs', expansion: 'unbreakable' }, 
  { label: 'Pointy Pebble', img: 'Pointy_Pebble', expansion: 'unbreakable' }, 
  { label: 'Pop Locs', img: 'Pop_Locs', expansion: 'unbreakable' }, 
  { label: 'Pro-seein Powder', img: 'Pro-seein_Powder', expansion: 'unbreakable' }, 
  { label: 'Re-Charge', img: 'Re-Charge', expansion: 'unbreakable' }, 
  { label: 'Recyclables', img: 'Recyclables', expansion: 'unbreakable' }, 
  { label: 'Safe-Cracker', img: 'Safe-Cracker', expansion: 'unbreakable' }, 
  { label: 'secrete dehodorant', img: 'secrete_dehodorant', expansion: 'unbreakable' }, 
  { label: 'Shiny Mirror', img: 'Shiny_Mirror', expansion: 'unbreakable' }, 
  { label: 'Soot Melange', img: 'Soot_Melange', expansion: 'unbreakable' }, 
  { label: 'Stone Helmet', img: 'Stone_Helmet', expansion: 'unbreakable' }, 
  { label: 'Tewaren Gem', img: 'Tewaren_Gem', expansion: 'unbreakable' }, 
  { label: 'The vicious cycle', img: 'The_vicious_cycle', expansion: 'unbreakable' }, 
  { label: 'Thick Bandage', img: 'Thick_Bandage', expansion: 'unbreakable' }, 
  { label: 'troll-Skin Coat', img: 'troll-Skin_Coat', expansion: 'unbreakable' }, 
  { label: 'trove converter', img: 'trove_converter', expansion: 'unbreakable' }, 
  { label: 'Well-Heeled Boots', img: 'Well-Heeled_Boots', expansion: 'unbreakable' },
  { label: 'advanced fishing rod', img: 'advanced_fishing_rod', expansion: 'undertow' }, 
{ label: 'augmented footwear', img: 'augmented_footwear', expansion: 'undertow' }, 
{ label: 'bananananas', img: 'bananananas', expansion: 'undertow' }, 
{ label: 'descaler', img: 'descaler', expansion: 'undertow' }, 
{ label: 'dragon scales', img: 'dragon_scales', expansion: 'undertow' }, 
{ label: 'fish dinner', img: 'fish_dinner', expansion: 'undertow' }, 
{ label: 'fortunate discovery 01 gasket', img: 'fortunate_discovery_01_gasket', expansion: 'undertow' }, 
{ label: 'fortunate discovery 02 stanza', img: 'fortunate_discovery_02_stanza', expansion: 'undertow' }, 
{ label: 'fortunate discovery 03 duster', img: 'fortunate_discovery_03_duster', expansion: 'undertow' }, 
{ label: 'fortunate discovery 04 mirawatt', img: 'fortunate_discovery_04_mirawatt', expansion: 'undertow' }, 
{ label: 'gearlocs guide', img: 'gearlocs_guide', expansion: 'undertow' }, 
{ label: 'handle hand', img: 'handle_hand', expansion: 'undertow' }, 
{ label: 'harpoon pistol', img: 'harpoon_pistol', expansion: 'undertow' }, 
{ label: 'hearing sensor', img: 'hearing_sensor', expansion: 'undertow' }, 
{ label: 'huckleberries', img: 'huckleberries', expansion: 'undertow' }, 
{ label: 'hunting net', img: 'hunting_net', expansion: 'undertow' }, 
{ label: 'jungle spear', img: 'jungle_spear', expansion: 'undertow' }, 
{ label: 'kakler nest', img: 'kakler_nest', expansion: 'undertow' }, 
{ label: 'kelp podwer', img: 'kelp_podwer', expansion: 'undertow' }, 
{ label: 'krelln caviar', img: 'krelln_caviar', expansion: 'undertow' }, 
{ label: 'loose wires', img: 'loose_wires', expansion: 'undertow' }, 
{ label: 'malfunctioninc mech', img: 'malfunctioninc_mech', expansion: 'undertow' }, 
{ label: 'night vision goggles', img: 'night_vision_goggles', expansion: 'undertow' }, 
{ label: 'oil no-essentials', img: 'oil_no-essentials', expansion: 'undertow' }, 
{ label: 'plated skull', img: 'plated_skull', expansion: 'undertow' }, 
{ label: 'powercell', img: 'powercell', expansion: 'undertow' }, 
{ label: 'programmable defibrillator', img: 'programmable_defibrillator', expansion: 'undertow' }, 
{ label: 'sibron kelp', img: 'sibron_kelp', expansion: 'undertow' }, 
{ label: 'simple snare', img: 'simple_snare', expansion: 'undertow' }, 
{ label: 'sleeping darts', img: 'sleeping_darts', expansion: 'undertow' }, 
{ label: 'spider silk salve', img: 'spider_silk_salve', expansion: 'undertow' }, 
{ label: 'tar, sap y twine', img: 'tar,_sap_y_twine', expansion: 'undertow' }
];