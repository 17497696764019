export const Trove = [
{ label: 'Dex infuser', img: 'Dex_infuser', expansion: 'base' }, 
{ label: 'Exploding shrooms', img: 'Exploding_shrooms', expansion: 'base' }, 
{ label: 'flash arrows', img: 'flash_arrows', expansion: 'base' }, 
{ label: 'Grappling Gun', img: 'Grappling_Gun', expansion: 'base' }, 
{ label: 'hunters body count necklace', img: 'hunters_body_count_necklace', expansion: 'base' }, 
{ label: 'laser pointer', img: 'laser_pointer', expansion: 'base' }, 
{ label: 'lightning on a stick', img: 'lightning_on_a_stick', expansion: 'base' }, 
{ label: 'locksmith manual', img: 'locksmith_manual', expansion: 'base' }, 
{ label: 'maximizer stim', img: 'maximizer_stim', expansion: 'base' }, 
{ label: 'mechanical boomerang', img: 'mechanical_boomerang', expansion: 'base' }, 
{ label: 'mechanical squirrel', img: 'mechanical_squirrel', expansion: 'base' }, 
{ label: 'micro neutral implant', img: 'micro_neutral_implant', expansion: 'base' }, 
{ label: 'minor patch kit', img: 'minor_patch_kit', expansion: 'base' }, 
{ label: 'pip-loc 2000', img: 'pip-loc_2000', expansion: 'base' }, 
{ label: 'Poison vial kit', img: 'Poison_vial_kit', expansion: 'base' }, 
{ label: 'reflex powder tin', img: 'reflex_powder_tin', expansion: 'base' }, 
{ label: 'Shock inducer', img: 'Shock_inducer', expansion: 'base' }, 
{ label: 'shoulder-mounted rock slinger', img: 'shoulder-mounted_rock_slinger', expansion: 'base' }, 
{ label: 'small spotting scope', img: 'small_spotting_scope', expansion: 'base' }, 
{ label: 'Utility belt', img: 'Utility_belt', expansion: 'base' }, 
{ label: 'aqua cannon', img: 'aqua_cannon', expansion: 'undertow' }, 
{ label: 'arm energizer', img: 'arm_energizer', expansion: 'undertow' }, 
{ label: 'black pearl', img: 'black_pearl', expansion: 'undertow' }, 
{ label: 'catapult', img: 'catapult', expansion: 'undertow' }, 
{ label: 'dragon center scale', img: 'dragon_center_scale', expansion: 'undertow' }, 
{ label: 'drone', img: 'drone', expansion: 'undertow' }, 
{ label: 'electrosynthetic explosive', img: 'electrosynthetic_explosive', expansion: 'undertow' }, 
{ label: 'fog machine', img: 'fog_machine', expansion: 'undertow' }, 
{ label: 'gadget armor', img: 'gadget_armor', expansion: 'undertow' }, 
{ label: 'mechanical squid', img: 'mechanical_squid', expansion: 'undertow' }, 
{ label: 'taclke box', img: 'taclke_box', expansion: 'undertow' }, 
{ label: 'watermask', img: 'watermask', expansion: 'undertow' },
{ label: 'baddie whistle', img: 'baddie_whistle', expansion: 'unbreakable' }, 
{ label: 'Chewable vitamins', img: 'Chewable_vitamins', expansion: 'unbreakable' }, 
{ label: 'Gearloc positioning system', img: 'Gearloc_positioning_system', expansion: 'unbreakable' }, 
{ label: 'Hastily Assembled Decoy', img: 'Hastily_Assembled_Decoy', expansion: 'unbreakable' }, 
{ label: 'Mechanical Bat', img: 'Mechanical_Bat', expansion: 'unbreakable' }, 
{ label: 'Nobulouss Personal Programmable Defibrillator', img: 'Nobulouss_Personal_Programmable_Defibrillator', expansion: 'unbreakable' }, 
{ label: 'output', img: 'output', expansion: 'unbreakable' }, 
{ label: 'Pacemaker', img: 'Pacemaker', expansion: 'unbreakable' }, 
{ label: 'Portable Cow', img: 'Portable_Cow', expansion: 'unbreakable' }, 
{ label: 'script', img: 'script', expansion: 'unbreakable' }, 
{ label: 'Serial Driller', img: 'Serial_Driller', expansion: 'unbreakable' }, 
{ label: 'Starter Pistol', img: 'Starter_Pistol', expansion: 'unbreakable' }, 
{ label: 'Crown of warspire frontal', img: 'Crown_of_warspire_frontal', expansion: 'automationofshale' }, 
{ label: 'Matroshkobolds frontal', img: 'Matroshkobolds_frontal', expansion: 'automationofshale' }, 
{ label: 'Tree Bark Armor frontal', img: 'Tree_Bark_Armor_frontal', expansion: 'automationofshale' }, 
{ label: 'Troll Mallet frontal', img: 'Troll_Mallet_frontal', expansion: 'automationofshale' }
];
